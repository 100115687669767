import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './pages/Dashboard';
import Pembelian from './pages/Kasir/Pembelian';
import Penjualan from './pages/Kasir/Penjualan';
import ListItemAdmin from './pages/ListItemAdmin';
import ListUsers from './pages/ListUsers';
import Login from './pages/Login';
import SuccessLogin from './pages/SuccessLogin';
import ProtectedRoute from './utils/ProtectedRoute';
import { ToastContainer } from 'react-toastify';
import ListPembelian from './pages/ListPembelian';
import BarangOpname from './pages/BarangOpname/BarangOpname';
import TrxPenjualan from './pages/Transaksi/TrxPenjualan';
import TrxPembelian from './pages/Transaksi/TrxPembelian';
import SaldoKAS from './pages/SaldoKAS';
import Summary from './pages/Summary';
import PengembalianBarang from './pages/Kasir/PengembalianBarang';
import HistorySaldoKasir from './pages/Kasir/HistorySaldoKasir';
import TambahBarang from './pages/operator/TambahBarang';
import BarangRusak from './pages/BarangRusak';
import LaporanKaratItem from './pages/LaporanKaratItem';
import Pengeluaran from './pages/Pengeluaran';
import TransaksiLuar from './pages/TransaksiLuar';
import LaporanCashflow from './pages/LaporanCashflow';
import TransaksiTertunda from './pages/transaksiTertunda/transaksiTertunda';
import TransferBarang from './pages/TransferBarang/transferBarang';
import ListTransaksiTertunda from './pages/transaksiTertunda/ListTransaksiTertunda';
import HistoriTransferMasuk from './pages/HistoryTransfer/HistoriTransferMasuk';
import HistoriTransferKeluar from './pages/HistoryTransfer/HistoriTransferKeluar';
import Riwayat from './pages/riwayat';
import BarangTerhapus from './pages/BarangTerhapus';
import PageNotFound from './pages/PageNotFound';
import GaleriBarang from './pages/galeri/GaleriBarang';
import GaleriPenjualan from './pages/galeri/GaleriPenjualan';
import GaleriPembelian from './pages/galeri/GaleriPembelian';
import LabaPenjualan from './pages/LabaPenjualan';

const routes = [
  { path: '/login', element: <Login /> },
  { path: '/success', element: <SuccessLogin />, protected: true },
  { path: '/pengeluaran', element: <Pengeluaran />, protected: true },
  { path: '/barang-terhapus', element: <BarangTerhapus />, protected: true },
  { path: '/laporan-cashflow', element: <LaporanCashflow />, protected: true },
  { path: '/riwayat', element: <Riwayat />, protected: true },
  { path: '/trx-luar', element: <TransaksiLuar />, protected: true },
  { path: '/galeri-barang', element: <GaleriBarang />, protected: true },
  { path: '/galeri-penjualan', element: <GaleriPenjualan />, protected: true },
  { path: '/galeri-pembelian', element: <GaleriPembelian />, protected: true },
  { path: '/tambah-barang', element: <TambahBarang />, protected: true },
  { path: '/transfer-barang', element: <TransferBarang />, protected: true },
  { path: '/list-transaksi-tertunda', element: <ListTransaksiTertunda />, protected: true },
  { path: '/laporan-karat-item', element: <LaporanKaratItem />, protected: true },
  { path: '/transaksi-tertunda', element: <TransaksiTertunda />, protected: true },
  { path: '/', element: <Dashboard />, protected: true },
  { path: '/history-saldo-kasir', element: <HistorySaldoKasir />, protected: true },
  { path: '/list-items-admin', element: <ListItemAdmin />, protected: true },
  { path: '/histori-transfer-masuk', element: <HistoriTransferMasuk />, protected: true },
  { path: '/histori-transfer-keluar', element: <HistoriTransferKeluar />, protected: true },
  { path: '/list-users', element: <ListUsers />, protected: true },
  { path: '/penjualan', element: <Penjualan />, protected: true },
  { path: '/pengembalian', element: <PengembalianBarang />, protected: true },
  { path: '/pembelian', element: <Pembelian />, protected: true },
  { path: '/data-pembelian', element: <ListPembelian />, protected: true },
  { path: '/barang-opname', element: <BarangOpname />, protected: true },
  { path: '/transaksi-penjualan', element: <TrxPenjualan />, protected: true },
  { path: '/transaksi-pembelian', element: <TrxPembelian />, protected: true },
  { path: '/saldo-kas', element: <SaldoKAS />, protected: true },
  { path: '/summary', element: <Summary />, protected: true },
  { path: '/barang-rusak', element: <BarangRusak />, protected: true },
  { path: '/laba-penjualan', element: <LabaPenjualan />, protected: true },
  { path: '*', element: <PageNotFound /> },
];

function App() {
  return (
    <>
      <Router>
        <Routes>
          {routes.map(({ path, element, protected: isProtected }) => (
            <Route
              key={path}
              path={path}
              element={isProtected ? <ProtectedRoute>{element}</ProtectedRoute> : element}
            />
          ))}
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
