import { Dialog, Transition } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { getAuthorizationHeader, http } from '../../config/http';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IconAdjustmentsHorizontal, IconX, IconTrash, IconXboxX } from '@tabler/icons-react';
import LoadingIndicator from '../LoadingIndicator';

export default function PindahBarangBanyak({
  setOpenPindahBanyakBarang,
  refreshPage,
  reloadDate,
  openPindahBarangBanyak,
  cabang,
  setSelectedItems,
  selectedItems,
  resetSelectPindahItems,
  closeModalBanyakBarang,
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const ids = selectedItems.map((item) => item.id);
  const [selectCabang, setSelectCabang] = useState([]);
  const [idCabang, setIdCabang] = useState(0);


  useEffect(() => {
    async function getCabangSelect() {
      await http
        .get(`cabang/brankas`, {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setSelectCabang(res.data.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
    }
    getCabangSelect();
  }, [navigate]);

  const [cabangId, setCabangId] = useState(0);

  async function handleSubmitBrankasToEtalase(e) {
    e.preventDefault();
    setLoading(true);
    const formData = {
      items: ids,
      cabang_id: cabangId
    }
    if (cabangId !== 0) {
      try {
        const response = await http.post('/item/pindah_etalase', formData, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        // console.log(response.data)
        toast.success('Data berhasil dipindah', { autoClose: 600, });
        setOpenPindahBanyakBarang(false);
        reloadDate();
        setLoading(false);
        resetSelectPindahItems();
        return response.data
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error('Pilih toko dulu', { autoClose: 600, });
    }
    setLoading(false);
  }
  async function handleSubmitPindahBarang(e) {
    e.preventDefault();
    setLoading(true);
    const formData = {
      cabang_id: idCabang,
      items: ids,
    };
    if (idCabang !== 0) {
      try {
        const response = await http.post('/item/transfer', formData, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        // console.log(response.data)
        toast.success('Data berhasil dipindah', { autoClose: 600, });
        setOpenPindahBanyakBarang(false);
        refreshPage();
        setLoading(false);
        return response.data
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error('Pilih cabang tujuan', { autoClose: 600, });
    }
    setLoading(false);
  }
  function fake() { }
  function removeSelectedItem(itemId) {
    setSelectedItems(currentItems => currentItems.filter(item => item.id !== itemId));
  }
  return (
    <>
      {loading &&
        <LoadingIndicator />
      }
      <Transition appear show={openPindahBarangBanyak} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={fake}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center p-4 min-h-[440px]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full max-w-[40rem] transform overflow-hidden bg-white p-2 print:border text-left align-middle shadow-xl print:shadow-none transition-all`}>
                  <div>
                    <div className='flex items-center justify-between'>
                      <Dialog.Title as="h1" className={'text-[20px] m-3'}>
                        Barang Brankas ke Etalase Toko
                      </Dialog.Title>
                      <p>Jumlah : {ids?.length} Barang</p>
                    </div>
                    <div className="relative overflow-x-auto mt-6">
                      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" className="px-2 py-2">
                              No
                            </th>
                            <th scope="col" className="px-2 py-2">
                              Kode Barang
                            </th>
                            <th scope="col" className="px-2 py-2">
                              Nama Barang
                            </th>
                            <th scope="col" className="px-2 py-2">
                              Berat
                            </th>
                            <th scope="col" className="px-2 py-2">
                              Batalkan
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedItems?.map((bt, i) => (
                            <tr
                              key={i}
                              className="hover:bg-gray-100 bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                            >
                              <th
                                scope="row"
                                className="px-2 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                              >
                                {i + 1}.
                              </th>
                              <td className="px-1 py-1">{bt?.kode_item}</td>
                              <td className="px-1 py-1">{bt?.jenis_kategori?.nama_jenis}</td>
                              <td className="px-1 py-1">{bt?.berat} gr</td>
                              <td className="px-1 py-1 text-center">
                                <button
                                  onClick={() => removeSelectedItem(bt.id)}
                                  className="py-1.5 px-0.5 [&>svg]:w-5 h-7 flex items-center justify-center [&>svg]:h-5 [&>svg]:stroke-1 rounded-[2px] bg-orange-500 text-white hover:bg-orange-600 transition duration-300 text-xs"
                                >
                                  <IconXboxX size={15} />
                                </button>
                                {/* <IconX className='w-4 h-4 cursor-pointer' onClick={() => removeSelectedItem(bt.id)} /> */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="mt-4">
                        <select
                          name=""
                          id=""
                          onChange={(e) => setCabangId(e.target.value)}
                          className=" p-2 rounded border cursor-pointer w-full"
                        >
                          <option value="">Pilih brankas atau etalase</option>
                          {cabang?.map((cb) => (
                            <option value={cb.id} key={cb.id}>
                              {cb?.nama_cabang}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mt-4 gap-1 flex justify-end print:invisible">
                        <button
                          type="button"
                          disabled={loading}
                          className={`${loading && 'cursor-not-allowed'} bg-slate-500 text-white font-semibold hover:bg-slate-600 transition duration-300 px-4 py-2 `}
                          onClick={closeModalBanyakBarang}
                        >
                          Batal
                        </button>
                        <button
                          type="button"
                          disabled={loading}
                          className={`${loading && 'cursor-wait'} bg-green-500 text-white font-semibold hover:bg-green-600 transition duration-300 px-4 py-2 `}
                          onClick={handleSubmitBrankasToEtalase}
                        >
                          {loading ? 'Loading..' : 'Pindah Barang'}
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
