import { Dialog, Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { CurrencyInput } from '../../utils/CurrencyInput';
import { getAuthorizationHeader, http } from '../../config/http';
import { toast } from 'react-toastify';

export default function DetailTransaksiLuar({ closeModal, label, detailPenjualan, getPengeluaranHariIni, isOpen }) {
    // console.log(detailPenjualan)
    const [title, setTittle] = useState('');
    const [nominal, setNominal] = useState('');
    const [tipe, setTipe] = useState('');
    const [keterangan, setKeterangan] = useState('');
    function numberWithCommas(x) {
        return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (isOpen) {
            setTittle(detailPenjualan.title);
            setNominal(detailPenjualan.nominal);
            setTipe(detailPenjualan.tipe)
            setKeterangan(detailPenjualan.keterangan);
        }
    }, [isOpen]);

    async function submitEditTransaksiLuar(e) {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await http.put(`/cashflow/${detailPenjualan.id}`, {
                title, nominal, tipe, keterangan
            }, {
                headers: { Authorization: getAuthorizationHeader() }
            })
            toast.success('Data berhasil diupdate', { autoClose: 600, });
            closeModal();
            getPengeluaranHariIni();
            setLoading(false);
            return response.data;
        } catch (error) {
            console.log(error)
            if (error.status === 404) {
                toast.error(error.response.data.message, { autoClose: 600, })
            }
            setLoading(false);
        }
        setLoading(false)
    }



    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>


                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center p-4 min-h-[440px]">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded- bg-white px-6 pt-5 pb-3 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title as="h2" className="text-xl font-medium leading-6 text-gray-900">
                                        Pengeluaran dan Pemasukan
                                    </Dialog.Title>
                                    <div className="grid gap-2">
                                        <div className="border rounded">
                                            <div className="p-1 border-b bg-orange-500/10 text-slate-600">
                                                <h2>Edit Pengeluaran</h2>
                                            </div>
                                            <div>
                                                <div className="mb-6 mx-2">
                                                    <label htmlFor="berat" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                        Title
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="berat"
                                                        name="berat"
                                                        autoComplete="off"
                                                        defaultValue={detailPenjualan.title || ''}
                                                        onChange={(e) => setTittle(e.target.value)}
                                                        // onChange={handleOnChange}
                                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                                    />
                                                </div>
                                                <div className="mx-2 mb-4">
                                                    <label htmlFor="nominal" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">
                                                        Nominal
                                                    </label>

                                                    <CurrencyInput
                                                        name="nominal"
                                                        autoComplete="off"
                                                        value={nominal}
                                                        onChange={(value) => setNominal(value)}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    />
                                                </div>
                                                <div className='mx-2 mb-6'>
                                                    <label htmlFor="tipe" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                        Tipe
                                                    </label>
                                                    <select
                                                        id="tipe"
                                                        onChange={(e) => setTipe(e.target.value)}
                                                        defaultValue={detailPenjualan.tipe || ''}
                                                        name="tipe"
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    >
                                                        <option>Pilih Type</option>
                                                        <option value="pengeluaran">Pengeluaran</option>
                                                        <option value="pemasukan">Pemasukan</option>
                                                        <option value="m-banking">M-Banking</option>
                                                    </select>
                                                </div>
                                                <div className="mb-6 mx-2">
                                                    <label htmlFor="berat" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                        Keterangan
                                                    </label>
                                                    <textarea
                                                        type="text"
                                                        id="berat"
                                                        name="berat"
                                                        autoComplete="off"
                                                        defaultValue={detailPenjualan.keterangan || ''}
                                                        onChange={(e) => setKeterangan(e.target.value)}
                                                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3 flex items-center justify-end gap-3">
                                            <button
                                                onClick={closeModal}
                                                className="flex items-center bg-slate-500 p-2 font-light hover:bg-slate-600 transition duration-500 rounded text-white"
                                            >
                                                Tutup
                                            </button>
                                            <button
                                                onClick={submitEditTransaksiLuar}
                                                disabled={loading}
                                                className={`${loading && 'cursor-wait'} flex items-center bg-green-500 p-2 font-light hover:bg-green-600 transition duration-500 rounded text-white`}
                                            >
                                                {loading ? 'Loading...' : 'Simpan'}
                                            </button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
