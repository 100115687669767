import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { http, getAuthorizationHeader } from '../../config/http';
import effectTambah from '../../assets/tambah.mp3';
import KodeItemShow from './KodeItemShow';
import { CurrencyInput } from '../../utils/CurrencyInput';
import { IconX } from '@tabler/icons-react';

export default function InputBarang({ isOpen, closeModal, kategori, setIsSubmitItem }) {
  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);
  const [jenisKategori, setJenisKategori] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cabang, setCabang] = useState([]);
  const [harga_modal, setHargaModal] = useState(0);
  const [formData, setFormData] = useState({
    jenis_kategori_id: '',
    berat: '',
    karat: '',
    cabang_id: '',
  });

  const [nomor_rak, setNomerRak] = useState('');
  const [selectImage, setSelectImage] = useState([]);
  const [gambar1, setGambar1] = useState('');
  const [gambar2, setGambar2] = useState('');
  const [gambar3, setGambar3] = useState('');
  let [openKode, setOpenKode] = useState(false);
  const [kode, setKode] = useState('');

  const navigate = useNavigate();

  const [req, setReq] = useState({
    KategoriName: '',
    jenis_kategori_id: '',
  });

  useEffect(() => {
    const regex = /^\d*\.?\d{0,3}$/;
    if (formData.berat && !regex.test(formData.berat)) {
      setFormData({
        berat: formData.berat.slice(0, -1),
      });
    }
  }, [formData.berat]);

  function playSound() {
    new Audio(effectTambah).play();
  }

  function handleOnChange(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  function resetForm() {
    setFormData({
      jenis_kategori_id: '',
      berat: '',
      karat: '',
      cabang_id: '',
    });
    setHargaModal(0);
    setNomerRak('');
    setGambar1('');
    setGambar2('');
    setGambar3('');
    setSelectImage([]);
    // console.log('Hallo')
  }
  function resetFormSecond() {
    setReq({
      KategoriName: '',
    });
  }

  const { jenis_kategori_id, karat, berat, cabang_id } = formData;
  // console.log({ jenis_kategori_id, berat, karat, harga_modal, cabang_id, nomor_rak, gambar1 })
  async function handleSubmitItem(e) {
    e.preventDefault();
    const data = { jenis_kategori_id, berat, karat, harga_modal, cabang_id, nomor_rak, gambar1 };
    setLoading(true);
    try {
      const response = await http.post(`/item`, data, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      setLoading(false);
      playSound();
      // setOpenKode(true);
      setIsSubmitItem();
      setKode(response.data.data.kode_item === undefined ? '' : response.data.data.kode_item);
      toast.success(response.data.message, { autoClose: 600, });
      resetForm();
      resetFormSecond();
      // console.log(response.data);
      return response.data.message;
    } catch (error) {
      // console.log('error::: ', error);
      // toast.warning(error.response.data.message, {
      //   autoClose: 1000,
      // });
      if (error.response.status === 401) {
        navigate('/login');
      } else if (error.response.status === 422) {
        toast.warning('Periksa input data', { autoClose: 600, });
      }
    }
    setLoading(false);
  }

  async function SelectJenisKategori(e) {
    let name = e.target.name;
    let value = e.target.value;
    req[name] = value;
    let idData = value;
    await http
      .get(`/jenis/kategori/${idData}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        setJenisKategori(res.data.data);
        return res.data.data;
      })
      .then((resId) => {
        setReq(resId);
      })
      .catch((err) => {
        console.log('error jenis kategori', err);
      });
  }
  useEffect(() => {
    async function getCabang() {
      await http
        .get('/cabang', {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setCabang(res.data.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          }
        });
    }
    getCabang();
  }, []);

  function closeOpenKode() {
    setOpenKode(false);
    closeModal();
  }

  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    // console.log('selectedFiles::: ', selectedFiles);
    const selectedFilesArray = Array.from(selectedFiles);

    if (selectImage.length < 1) {
      const reader1 = new FileReader();
      reader1.addEventListener('load', function () {
        setGambar1(reader1.result.split(',')[1]);
      });
      reader1.readAsDataURL(selectedFiles[0]);
    }

    // const file = event.target.files[0];
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setSelectImage((previousImages) => previousImages.concat(imagesArray));
    // FOR BUG IN CHROME
    event.target.value = '';
  };
  function deleteHandler(image) {
    setSelectImage(selectImage.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        {loading ? (
          <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700/20 opacity-75 flex flex-col items-center justify-center">
            <div className="w-12 h-12  rounded-full animate-spin border-4 border-dashed border-green-500 border-t-transparent"></div>
          </div>
        ) : (
          <></>
        )}

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Tambah Barang
                </Dialog.Title>
                <div>
                  <KodeItemShow closeOpenKode={closeOpenKode} openKode={openKode} kode={kode} />
                </div>
                <div className="mt-2">
                  <form onSubmit={handleSubmitItem}>
                    <div className="grid grid-cols-2 gap-2">
                      <div className="mb-6">
                        <label
                          htmlFor="kategori"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Pilih Kategori
                        </label>
                        <select
                          onChange={SelectJenisKategori}
                          name="KategoriName"
                          className="select select-bordered w-full"
                        >
                          <option value="">Pilih Kategori</option>
                          {kategori.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.nama_kategori}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-6">
                        <label
                          htmlFor="kategori"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Pilih Jenis
                        </label>
                        <select
                          value={formData.jenis_kategori_id}
                          onChange={handleOnChange}
                          name="jenis_kategori_id"
                          className="select select-bordered w-full"
                        >
                          <option value="">Pilih Jenis</option>
                          {jenisKategori.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.nama_jenis}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-2 mb-6">
                      <div>
                        <label htmlFor="berat" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          Berat
                        </label>
                        <input
                          type="number"
                          id="berat"
                          name="berat"
                          autoComplete="off"
                          value={formData.berat}
                          onChange={handleOnChange}
                          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                        />
                      </div>
                      <div>
                        <label htmlFor="karat" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          Karat
                        </label>
                        <input
                          type="number"
                          id="karat"
                          value={formData.karat}
                          autoComplete={'off'}
                          onChange={handleOnChange}
                          name="karat"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          required
                        />
                      </div>
                    </div>
                    <div className="mb-6">
                      <label
                        htmlFor="nomor_rak"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Nomor BAKI
                      </label>
                      <input
                        type="text"
                        id="nomor_rak"
                        value={nomor_rak}
                        autoComplete={'off'}
                        onChange={(e) => setNomerRak(e.target.value)}
                        name="nomor_rak"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                      />
                    </div>
                    <div className="mb-6">
                      <label
                        htmlFor="harga_modal"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Harga Modal / Gram
                      </label>
                      <CurrencyInput
                        name="harga_modal"
                        value={harga_modal}
                        autoComplete="off"
                        onChange={(value) => setHargaModal(() => value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                    <div className="mb-6">
                      <label
                        htmlFor="cabang_id"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Pilih Etalase
                      </label>
                      <select
                        id="cabang_id"
                        name="cabang_id"
                        value={formData.cabang_id}
                        onChange={handleOnChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="">Pilih Etalase</option>
                        {cabang.map((cb) => (
                          <option value={cb.id} key={cb.id}>
                            {cb.nama_cabang}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-6">
                      <div>
                        <div className={`mt-2 flex gap-4 p-2 ${selectImage.length === 0 ? 'hidden' : ''}`}>
                          {selectImage.map((image, index) => (
                            <div key={index}>
                              <button
                                onClick={() => deleteHandler(image)}
                                className="absolute bg-white/50 rounded-full p-1"
                              >
                                <IconX width={20} />
                              </button>
                              <img width={90} src={image} alt="" srcSet="" />
                            </div>
                          ))}
                        </div>
                        <div>
                          {selectImage.length > 0 &&
                            (selectImage.length > 1 ? (
                              <div>
                                <p className="text-sm mx-3 text-red-500">
                                  Sementara Kamu Hanya bisa Upload 1 Gambar <br />
                                  <span className="text-sm">
                                    Silahkan Hapus <b> {selectImage.length - 1} </b> dari {selectImage.length} Gambar
                                    diatas
                                  </span>
                                </p>
                              </div>
                            ) : (
                              <div className="text-sm text-green-600 mb-2 mx-3">
                                UPLOAD {selectImage.length} IMAGE
                                {selectImage.length === 1 ? '' : 'S'}
                              </div>
                            ))}
                        </div>
                        <input
                          type="file"
                          onChange={handleImageChange}
                          className="
                          file:bg-blue-500 file:rounded-md file:border-none w-full  file:p-2 file:text-white file:cursor-pointer
                            bg-green-500/20 rounded-md  p-2 font-semibold
                        "
                          multiple
                          accept="image/png , image/jpeg, image/webp"
                        />
                      </div>
                    </div>
                    <div className="flex justify-end gap-2">
                      <button
                        type="reset"
                        onClick={closeModal}
                        // disabled={loading ? true : false}
                        className="text-white  bg-slate-600 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800"
                      >
                        Batal
                      </button>
                      <button
                        type="submit"
                        disabled={loading ? true : false}
                        className="text-white  bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                      >
                        {loading ? 'Proses..' : 'Simpan'}
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
