import { IconReload } from '@tabler/icons-react';
import React from 'react';
import moment from 'moment';

function BarangTerkini({
  totalHargaBarangTerkini,
  cabangFilterSm,
  totalBeratBarangTerkini,
  totalBijiBarangTerkini,
  loadingBarangTerkini,
  barangTerkini,
  setDateFilterBT,
  setFilterCabangBT,
  reloadBarangTerkini,
  setKaratFilterBT,
}) {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  function handleSelect(date) {
    // console.log(moment(date).format('DD-MM-YYYY'));
    setDateFilterBT(moment(date).format('DD-MM-YYYY'));
  }

  const karatOption = [
    { karat: 16 },
    { karat: 17 },
    { karat: 18 },
    { karat: 19 },
    { karat: 20 },
    { karat: 21 },
    { karat: 22 },
    { karat: 23 },
    { karat: 24 },
  ];

  // onChange={handleSelect}
  return (
    <div className="">
      <div className="px-4 pt-2 pb-1 flex items-center justify-between  bg-green-500/50">
        <h6 className="font-bold uppercase text-sm text-slate-900">Terkini</h6>
        <div className="flex items-center gap-3">
          <select className="p-1 cursor-pointer" onChange={(e) => setFilterCabangBT(e.target.value)} name="" id="">
            <option value="">Pilih Etalase</option>
            {cabangFilterSm.map((cbf) => (
              <option key={cbf.id} value={cbf.id}>
                {cbf.nama_cabang}
              </option>
            ))}
          </select>
          <select id="" onChange={(e) => setKaratFilterBT(e.target.value)} className="p-1 cursor-pointer">
            <option value=" ">Pilih Karat</option>
            {karatOption.map((item, i) => (
              <option key={i} value={item.karat}>
                {item.karat}
              </option>
            ))}
          </select>
          {/* <div className="dropdown dropdown-end bg-white rounded-md  p-2">
            <button tabIndex={0}>
              <IconCalendarEvent />
            </button>
            <div tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box ">
            <Calendar  onChange={handleSelect}  date={new Date()} />
            </div>
          </div> */}

          <button onClick={reloadBarangTerkini} className="bg-orange-500 text-white p-1 rounded">
            <IconReload size={20} />
          </button>
        </div>
      </div>
      <div className="p-2 overflow-x-auto">
        {loadingBarangTerkini ? (
          <div className=" font-semibold px-2 py-1 rounded-br-md rounded-bl-md flex items-center gap-3">
            <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    '.spinner_Wezc{transform-origin:center;animation:spinner_Oiah .75s step-end infinite}@keyframes spinner_Oiah{8.3%{transform:rotate(30deg)}16.6%{transform:rotate(60deg)}25%{transform:rotate(90deg)}33.3%{transform:rotate(120deg)}41.6%{transform:rotate(150deg)}50%{transform:rotate(180deg)}58.3%{transform:rotate(210deg)}66.6%{transform:rotate(240deg)}75%{transform:rotate(270deg)}83.3%{transform:rotate(300deg)}91.6%{transform:rotate(330deg)}100%{transform:rotate(360deg)}}',
                }}
              />
              <g className="spinner_Wezc">
                <circle cx={12} cy="2.5" r="1.5" opacity=".14" />
                <circle cx="16.75" cy="3.77" r="1.5" opacity=".29" />
                <circle cx="20.23" cy="7.25" r="1.5" opacity=".43" />
                <circle cx="21.50" cy={12.0} r="1.5" opacity=".57" />
                <circle cx="20.23" cy="16.75" r="1.5" opacity=".71" />
                <circle cx="16.75" cy="20.23" r="1.5" opacity=".86" />
                <circle cx={12} cy="21.5" r="1.5" />
              </g>
            </svg>
            Memuat data....
          </div>
        ) : (
          <table className="w-full border text-sm text-left rounded-md overflow-hidden">
            <thead className="text-xs sticky top-0 border-b uppercase bg-gray-50 rounded-md dark:bg-gray-700">
              <tr>
                <th>No</th>
                <th>Kategori</th>
                <th>Biji</th>
                <th>Berat</th>
                <th>Modal</th>
              </tr>
            </thead>
            <tbody>
              {barangTerkini.map((bt, i) => (
                <tr className="border-b" key={i}>
                  <td>{i + 1}.</td>
                  <td>{bt.kategori}</td>
                  <td>{bt.jumlah_item_terkini}</td>
                  <td>{bt.total_berat_terkini}</td>
                  <td>Rp {numberWithCommas(bt.total_modal_terkini == undefined ? '0' : bt.total_modal_terkini)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <hr />
        <div className="mt-2 border text-sm rounded-md p-1 overflow-x-auto scrollbar-none flex items-center">
          {/* <div className="font-semibold flex justify-between">JUMLAH :  </div> */}
          <div className="whitespace-nowrap">
            [ <span className="text-red-600">{totalBijiBarangTerkini} </span> biji ]&nbsp;
          </div>
          <div className="whitespace-nowrap ml-3">
            [ <span className="text-red-600">{totalBeratBarangTerkini} </span> gram ]&nbsp;
          </div>
          <div className="whitespace-nowrap ml-3">
            [{' '}
            <span className="text-red-600">
              Rp.{numberWithCommas(totalHargaBarangTerkini == undefined ? '0' : totalHargaBarangTerkini)}
            </span>{' '}
            ]
          </div>
        </div>
      </div>
    </div>
  );
}

export default BarangTerkini;
