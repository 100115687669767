import { IconActivity, IconCalendarEvent, IconLogout, IconReload } from '@tabler/icons-react';
import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Calendar } from 'react-date-range';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SpinnerGetData from '../components/common/SpinnerGetData';
import { getAuthorizationHeader, http } from '../config/http';
import Layouts from '../layouts/Layouts';

export default function LaporanKaratItem() {
  const navigate = useNavigate();

  const [barangTerkini, setBarangTerkini] = useState([]);
  const [loadingBarangTerkini, setLoadingBarangTerkini] = useState(false);
  const [totalHargaBarangTerkini, setTotalHargaBarangTerkini] = useState('');
  const [totalBeratBarangTerkini, setTotalBeratBarangTerkini] = useState('');
  const [totalBijiBarangTerkini, setTotalBijiBarangTerkini] = useState('');
  const [cabangFilterSm, setCabangFilterSm] = useState([]);
  const [idCabangFilterBT, setFilterCabangBT] = useState('');
  const [dateFilterBT, setDateFilterBT] = useState('');
  useEffect(() => {
    async function getCabangFilterSm() {
      await http
        .get(`/cabang`, {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setCabangFilterSm(res.data.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
    }
    getCabangFilterSm();
  }, [navigate]);
  async function getBarangTerkini() {
    setLoadingBarangTerkini(true);
    await http
      .get(
        `summary/item_terkini${idCabangFilterBT === '' ? '' : `?cabang=${idCabangFilterBT}`}${
          dateFilterBT === '' ? `` : `${idCabangFilterBT === '' ? `?` : `&`}tanggal=${dateFilterBT}`
        }`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        },
      )
      .then((res) => {
        // console.log('res::: ', res);
        setBarangTerkini(res.data.data);
        setTotalBeratBarangTerkini(res.data.total_berat);
        setTotalBijiBarangTerkini(res.data.total_item);
        setTotalHargaBarangTerkini(res.data.total_modal);
      })
      .catch((err) => {
        if (err.response === 'undefined') {
          return null;
        } else if (err.response.status === 401) {
          navigate('/login');
        }
      });
    setLoadingBarangTerkini(false);
  }
  useEffect(() => {
    getBarangTerkini();
  }, [navigate, idCabangFilterBT, dateFilterBT]);

  function reloadBarangTerkini() {
    const nextDate = '';
    // const nextCabang = ''
    getBarangTerkini();
    setDateFilterBT(nextDate);
    // setFilterCabangBT(nextCabang);
  }
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  function handleSelect(date) {
    // console.log(moment(date).format('DD-MM-YYYY'));
    setDateFilterBT(moment(date).format('DD-MM-YYYY'));
  }

  const [karatItem, setKaratItem] = useState([]);
  const [loadingKaratItem, setLoadingKaratItem] = useState(false);
  const [dateFilterKaratItem, setDateFilterKaratItem] = useState('');
  const [idCabangFilterKaratItem, setIsCabangFilterKaratItem] = useState('');

  async function getKaratItem() {
    setLoadingKaratItem(true);
    const filterKaratItem = `${idCabangFilterKaratItem === '' ? '' : `?cabang=${idCabangFilterKaratItem}`}${
      dateFilterKaratItem === '' ? `` : `${idCabangFilterKaratItem === '' ? `?` : `&`}tanggal=${dateFilterKaratItem}`
    }`;
    await http
      .get(`summary/karat_item${filterKaratItem}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        // console.log('res halo::: ', res.data);
        // console.log(res.data.data);
        setKaratItem(res.data.data);
      })
      .catch((err) => {
        if (err.response === 'undefined') {
          return null;
        } else if (err.response.status === 401) {
          navigate('/login');
        }
      });
    setLoadingKaratItem(false);
  }
  function reloadKaratItem() {
    const nextDate = '';
    // const nextCabang = ''
    getKaratItem();
    setDateFilterKaratItem(nextDate);
    // setFilterCabangBT(nextCabang);
  }
  useEffect(() => {
    getKaratItem();
  }, [idCabangFilterKaratItem, dateFilterKaratItem]);

  function handleSelectKaratItem(date) {
    setDateFilterKaratItem(moment(date).format('DD-MM-YYYY'));
  }

  const [jumlahBarangKeluarHariIni, setJumlahBarangKeluarHariIni] = useState(0);
  const [jumlahBarangMasukHariIni, setJumlahBarangMasukKariIni] = useState(0);
  const [jumlahBeratBarangMasukHariIni, setJumalahBeratBarangMasukHariIni] = useState(0);
  const [jumlahBeratBarangKeluarHariIni, setJumlahBeratBarangKeluarHariIni] = useState(0);
  const [loadingGetDataHome, setLoadingGetDataHome] = useState(false);

  useEffect(() => {
    async function getTransaksiHariIni() {
      setLoadingGetDataHome(true);
      await http
        .get(`home`, {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          console.log('res::: ', res.data);
          setJumlahBarangKeluarHariIni(res.data.jumlah_barang_keluar_hari_ini);
          setJumlahBarangMasukKariIni(res.data.jumlah_barang_masuk_hari_ini);
          setJumlahBeratBarangKeluarHariIni(res.data.total_berat_barang_keluar_hari_ini);
          setJumalahBeratBarangMasukHariIni(res.data.total_berat_barang_masuk_hari_ini);
        })
        .catch((err) => {
          if (err.code === 'ERR_NETWORK') {
            toast.warning('Periksa jaringan internet', { autoClose: 600, });
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
      setLoadingGetDataHome(false);
    }
    getTransaksiHariIni();
  }, [navigate]);

  return (
    <Layouts title={'LAPORAN BARANG'}>
      <div className="grid sm:grid-cols-2 w-full">
        <div className="w-full">
          <div className="bg-white border  shadow p-[17px]">
            <div className="flex flex-row items-center">
              <div className="flex-shrink pr-4">
                <div className=" p-3 bg-yellow-600">
                  <IconActivity className="text-white" />
                </div>
              </div>
              <div className="flex-1 text-right ">
                <h5 className="font-bold uppercase text-gray-500">BARANG KE ETALASE HARI INI</h5>
                <h3 className="font-bold text-2xl">
                  {loadingGetDataHome ? (
                    <SpinnerGetData />
                  ) : (
                    <span>
                      {' '}
                      {jumlahBarangKeluarHariIni} EMAS | {jumlahBeratBarangKeluarHariIni} GRAM{' '}
                    </span>
                  )}
                  <span className="text-red-500">
                    <i className="fas fa-caret-up" />
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="bg-white border  shadow p-[17px]">
            <div className="flex flex-row items-center">
              <div className="flex-shrink pr-4">
                <div className=" p-3 bg-red-600">
                  <IconLogout className="text-white" />
                </div>
              </div>
              <div className="flex-1 text-right ">
                <h5 className="font-bold uppercase text-gray-500">BARANG KE BRANKAS HARI INI</h5>
                <h3 className="font-bold text-2xl">
                  {loadingGetDataHome ? (
                    <SpinnerGetData />
                  ) : (
                    <span>
                      {' '}
                      {jumlahBarangMasukHariIni} EMAS | {jumlahBeratBarangMasukHariIni} GRAM{' '}
                    </span>
                  )}
                  {/* {loadingGetDataHome ? <SpinnerGetData/> :<span>{jumlahBarangMasukHariIni} EMAS</span>} */}
                  <span className="text-red-500">
                    <i className="fas fa-caret-up" />
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* CONTAINER */}
      <div className="grid lg:grid-cols-2 gap-0.5 m-0.5">
        {/* COLUMS 1 */}
        <div className="w-full bg-white shadow-md">
          <div className="">
            <div className="px-4 pt-2 pb-1 flex items-center justify-between  bg-orange-500/30  ">
              <h3 className="font-bold uppercase text-xl text-slate-900 ">Terkini ( keluar / masuk )</h3>
              <div className="flex items-center gap-1">
                <select
                  className="select select-bordered rounded-none border-none"
                  onChange={(e) => setFilterCabangBT(e.target.value)}
                  name=""
                  id=""
                >
                  <option value="">Filter Etalase</option>
                  {cabangFilterSm.map((cbf) => (
                    <option key={cbf.id} value={cbf.id}>
                      {cbf.nama_cabang}
                    </option>
                  ))}
                </select>
                <button onClick={reloadBarangTerkini} className="bg-orange-500/80 text-white p-2.5">
                  <IconReload />
                </button>
              </div>
            </div>
            <div className="p-2">
              {loadingBarangTerkini ? (
                <div className=" font-semibold px-2 py-1 rounded-br-md rounded-bl-md flex items-center gap-3">
                  <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          '.spinner_Wezc{transform-origin:center;animation:spinner_Oiah .75s step-end infinite}@keyframes spinner_Oiah{8.3%{transform:rotate(30deg)}16.6%{transform:rotate(60deg)}25%{transform:rotate(90deg)}33.3%{transform:rotate(120deg)}41.6%{transform:rotate(150deg)}50%{transform:rotate(180deg)}58.3%{transform:rotate(210deg)}66.6%{transform:rotate(240deg)}75%{transform:rotate(270deg)}83.3%{transform:rotate(300deg)}91.6%{transform:rotate(330deg)}100%{transform:rotate(360deg)}}',
                      }}
                    />
                    <g className="spinner_Wezc">
                      <circle cx={12} cy="2.5" r="1.5" opacity=".14" />
                      <circle cx="16.75" cy="3.77" r="1.5" opacity=".29" />
                      <circle cx="20.23" cy="7.25" r="1.5" opacity=".43" />
                      <circle cx="21.50" cy={12.0} r="1.5" opacity=".57" />
                      <circle cx="20.23" cy="16.75" r="1.5" opacity=".71" />
                      <circle cx="16.75" cy="20.23" r="1.5" opacity=".86" />
                      <circle cx={12} cy="21.5" r="1.5" />
                    </g>
                  </svg>
                  Memuat data....
                </div>
              ) : (
                <table className="w-full border text-sm text-left text-gray-500 dark:text-gray-400 rounded-md overflow-hidden">
                  <thead className="text-xs sticky top-0 border-b text-gray-700 uppercase bg-gray-50 rounded-md dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-1">
                        No
                      </th>
                      <th scope="col" className="px-6 py-1">
                        Kategori
                      </th>
                      <th scope="col" className="px-6 py-1">
                        Biji
                      </th>
                      <th scope="col" className="px-6 py-1">
                        Berat
                      </th>
                      <th scope="col" className="px-6 py-1">
                        Modal
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {barangTerkini.map((bt, i) => (
                      <tr
                        key={i}
                        className="bg-white border-b dark:bg-gray-800 cursor-pointer dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      >
                        <td className="px-6 py-1 font-semibold text-black">{i + 1}</td>
                        <td className="px-6 py-1 font-semibold text-black">{bt.kategori}</td>
                        <td className="px-6 py-1 font-semibold text-black">{bt.jumlah_item_terkini}</td>
                        <td className="px-6 py-1 font-semibold text-black">{bt.total_berat_terkini}</td>
                        <td className="px-6 py-1 font-semibold text-black">
                          Rp {numberWithCommas(bt.total_modal_terkini == undefined ? '0' : bt.total_modal_terkini)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <hr />
              <div className="mt-5  border rounded-md p-2 flex items-center">
                {/* <div className="font-semibold flex justify-between">JUMLAH :  </div> */}
                <div className="whitespace-nowrap  border-black mr-6 font-semibold">
                  [ <span className="text-red-600">{totalBijiBarangTerkini} </span> biji ] &nbsp; &nbsp; &nbsp;
                </div>
                <div className="whitespace-nowrap mr-6  font-semibold">
                  [ <span className="text-red-600">{totalBeratBarangTerkini} </span> gram ] &nbsp; &nbsp; &nbsp;
                </div>

                <div className="whitespace-nowrap  font-semibold">
                  [{' '}
                  <span className="text-red-600">
                    Rp.{numberWithCommas(totalHargaBarangTerkini == undefined ? '0' : totalHargaBarangTerkini)}
                  </span>{' '}
                  ]
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* COLUMN 2 */}
        <div className="w-full bg-white shadow-md">
          <div className="">
            <div className="px-4 pt-2 pb-1 flex items-center justify-between  bg-green-500/30  ">
              <h3 className="font-bold uppercase text-xl text-slate-900 ">Karat (Barang)</h3>
              <div className="flex items-center gap-0.5">
                <select
                  className="select select-bordered rounded-none"
                  onChange={(e) => setIsCabangFilterKaratItem(e.target.value)}
                  name=""
                  id=""
                >
                  <option value="">Filter Etalase</option>
                  {cabangFilterSm.map((cbf) => (
                    <option key={cbf.id} value={cbf.id}>
                      {cbf.nama_cabang}
                    </option>
                  ))}
                </select>
                <div className="dropdown dropdown-end bg-white  p-2">
                  <button tabIndex={0}>
                    <IconCalendarEvent />
                  </button>
                  <div tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box ">
                    <Calendar onChange={handleSelectKaratItem} date={new Date()} />
                  </div>
                </div>
                <button onClick={reloadKaratItem} className="bg-orange-500/80 text-white p-2">
                  <IconReload />
                </button>
              </div>
            </div>
            <div className="p-2">
              {loadingKaratItem ? (
                <div className=" font-semibold px-2 py-1 rounded-br-md rounded-bl-md flex items-center gap-3">
                  <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          '.spinner_Wezc{transform-origin:center;animation:spinner_Oiah .75s step-end infinite}@keyframes spinner_Oiah{8.3%{transform:rotate(30deg)}16.6%{transform:rotate(60deg)}25%{transform:rotate(90deg)}33.3%{transform:rotate(120deg)}41.6%{transform:rotate(150deg)}50%{transform:rotate(180deg)}58.3%{transform:rotate(210deg)}66.6%{transform:rotate(240deg)}75%{transform:rotate(270deg)}83.3%{transform:rotate(300deg)}91.6%{transform:rotate(330deg)}100%{transform:rotate(360deg)}}',
                      }}
                    />
                    <g className="spinner_Wezc">
                      <circle cx={12} cy="2.5" r="1.5" opacity=".14" />
                      <circle cx="16.75" cy="3.77" r="1.5" opacity=".29" />
                      <circle cx="20.23" cy="7.25" r="1.5" opacity=".43" />
                      <circle cx="21.50" cy={12.0} r="1.5" opacity=".57" />
                      <circle cx="20.23" cy="16.75" r="1.5" opacity=".71" />
                      <circle cx="16.75" cy="20.23" r="1.5" opacity=".86" />
                      <circle cx={12} cy="21.5" r="1.5" />
                    </g>
                  </svg>
                  Memuat data....
                </div>
              ) : (
                <table className="w-full border text-sm text-left text-gray-500 dark:text-gray-400 rounded-md overflow-hidden">
                  <thead className="text-xs sticky top-0 border-b text-gray-700 uppercase bg-gray-50 rounded-md dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-1">
                        No
                      </th>
                      <th scope="col" className="px-6 py-1">
                        Karat
                      </th>
                      <th scope="col" className="px-6 py-1">
                        Biji
                      </th>
                      <th scope="col" className="px-6 py-1">
                        Berat
                      </th>
                      <th scope="col" className="px-6 py-1">
                        Modal
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {karatItem.map((ki, i) => (
                      <tr
                        key={i}
                        className="bg-white border-b dark:bg-gray-800 cursor-pointer dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      >
                        <td className="px-6 py-1 font-semibold text-black">{i + 1}</td>
                        <td className="px-6 py-1 font-semibold text-black">{ki.karat}</td>
                        <td className="px-6 py-1 font-semibold text-black">{ki.total_item}</td>
                        <td className="px-6 py-1 font-semibold text-black">{ki.total_berat}</td>
                        <td className="px-6 py-1 font-semibold text-black">
                          Rp {numberWithCommas(ki.total_modal == undefined ? '0' : ki.total_modal)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <hr />
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
}

// <div className="mt-5  border rounded-md p-2 flex items-center">
//                 {/* <div className="font-semibold flex justify-between">JUMLAH :  </div> */}
//                 <div className="whitespace-nowrap  border-black mr-6 font-semibold">
//                   [ <span className="text-red-600">{totalBijiBarangTerkini} </span> biji ] &nbsp; &nbsp; &nbsp;
//                 </div>
//                 <div className="whitespace-nowrap mr-6  font-semibold">
//                   [ <span className="text-red-600">{totalBeratBarangTerkini} </span> gram ] &nbsp; &nbsp; &nbsp;
//                 </div>

//                 <div className="whitespace-nowrap  font-semibold">
//                   [ <span className="text-red-600">Rp.{numberWithCommas(totalHargaBarangTerkini == undefined ? '0' : totalHargaBarangTerkini)}</span>{' '}
//                   ]
//                 </div>
//               </div>

// <div className="mt-5  border rounded-md p-2  flex items-center">
//                 {/* <div className="font-semibold flex justify-between">JUMLAH :  </div> */}
//                 <div className="whitespace-nowrap  border-black mr-6 font-semibold">
//                   [ <span className="text-red-600">{totalItemPerhariKaratPembelian}</span> biji ] &nbsp; &nbsp; &nbsp;
//                 </div>
//                 <div className="whitespace-nowrap mr-6  font-semibold">
//                   [ <span className="text-red-600">{totalBeratPerHariKaratPembelian}</span> gram ] &nbsp; &nbsp; &nbsp;
//                 </div>
//                 <div className="whitespace-nowrap mr-6  font-semibold">
//                   [{' '}
//                   <span className="text-red-600">
//                     Rp.{numberWithCommas(totalHargaPerhariKaratPembelian == undefined ? '0' : totalHargaPerhariKaratPembelian)}
//                   </span>{' '}
//                   ]
//                 </div>
//                 <div className="whitespace-nowrap mr-6 ml-2 font-semibold">
//                   [ rata-rata
//                   <span className="text-red-600">
//                     Rp.
//                     {numberWithCommas(isNaN(average) ? 0 : average)}
//                     {/* {numberWithCommas(totalHargaPenjualan / totalHargaPenjualan == undefined ? '0' : Math.round( totalHargaPenjualan / totalBeratPenjualan ))} */}
//                   </span>
//                   ] &nbsp; &nbsp; &nbsp;
//                 </div>
//               </div>
