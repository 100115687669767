import { Dialog, Transition } from '@headlessui/react';
import { IconX } from '@tabler/icons-react';
import { useState } from 'react';
import { useRef } from 'react';
import { Fragment } from 'react';
import ReactToPrint from 'react-to-print';
import { imageBaseUrl } from '../../config/http';
import ImageComponent from '../../utils/ImageComponent';
import PrintSuratTrxPenjualan from './PrintSuratTrxPenjualan';
import TestingPrint from './TestingPrint';
import SuratKeruak from './SuratKeruak';
import SuratToko from '../../components/surat/SuratToko';

export default function DetailBarangPenjualan({ closeModal, label, detailPenjualan, isOpen, isTertunda }) {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  const [openImage, setOpenImage] = useState(false);
  const [load, setLoad] = useState(false);

  const compSurat = useRef();
  function fake() { }

  const harga_modal_jual = detailPenjualan?.total_harga / detailPenjualan?.item?.berat;
  const tipe_surat = localStorage.getItem('tipe_surat');

  const tgl = String(detailPenjualan?.item?.created_at).split(' ');
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => { }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          {/* {tipe_surat === '1' ? (
            <div ref={compSurat} className="invisible print:visible absolute bg-white print:h-screen print:w-full">
              <PrintSuratTrxPenjualan isOpen={isOpen} detailPenjualan={detailPenjualan} />
            </div>
          ) : (
            <div ref={compSurat} className="invisible print:visible absolute bg-white print:h-screen print:w-full">
              <TestingPrint isOpen={isOpen} detailPenjualan={detailPenjualan} isTertunda={isTertunda} />
            </div>
          )} */}
          {tipe_surat === '1' ? (
            <div ref={compSurat} className="invisible print:visible absolute bg-white print:h-screen print:w-full">
              <PrintSuratTrxPenjualan isOpen={isOpen} detailPenjualan={detailPenjualan} />
            </div>
          ) : tipe_surat === '2' ? (
            <div ref={compSurat} className="invisible print:visible absolute bg-white print:h-screen print:w-full">
              <TestingPrint isOpen={isOpen} detailPenjualan={detailPenjualan} isTertunda={isTertunda} />
            </div>
          ) : tipe_surat === '3' ? (
            <div ref={compSurat} className="invisible print:visible absolute bg-white print:h-screen print:w-full">
              <SuratKeruak isOpen={isOpen} detailPenjualan={detailPenjualan} />
            </div>
          ) : tipe_surat === '4' ? (
            <div ref={compSurat} className="invisible print:visible absolute bg-white print:h-screen print:w-full">
              <SuratToko isOpen={isOpen} detailPenjualan={detailPenjualan}  isTertunda={isTertunda}/>
            </div>
          ):  null}
          {openImage && isOpen && (
            <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700/20  flex flex-col items-center justify-center">
              <div
                onClick={() => setOpenImage(false)}
                className={'absolute top-52 right-7 lg:top-16 lg:right-[280px] md:right-[120px] md:top-28 '}
              >
                <div className="bg-white p-2 rounded-full cursor-pointer hover:bg-white/50">
                  <IconX />
                </div>
              </div>
              <div>
                {!load && (
                  <div className="max-w-[48px] max-h-[48px]  rounded-full animate-spin border-4 border-dashed border-green-500 border-t-transparent"></div>
                )}
                <img
                  onLoad={() => setLoad(true)}
                  width={''}
                  className={'lg:w-[700px] md:w-[500px] w-[350px] scale-50'}
                  height={''}
                  src={
                    detailPenjualan.item?.gambar1 === null
                      ? ''
                      : `${imageBaseUrl}${detailPenjualan.item?.gambar1[0].asli}`
                  }
                />
              </div>
            </div>
          )}
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center p-4 min-h-[440px]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded- bg-white px-6 pt-5 pb-3 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h2" className="text-xl font-medium leading-6 text-gray-900">
                    {label} <br />
                    KODE FAKTUR : <span className="font-light">{detailPenjualan?.kode_trx}</span>
                  </Dialog.Title>
                  <div className="grid gap-2">
                    <div className="border rounded">
                      <div className="p-1 border-b bg-orange-500/10 text-slate-600">
                        <h2>Detail Transaksi</h2>
                      </div>
                      <div className="border-b mt-1 text-base font-normal text-slate-600 flex justify-between px-5">
                        Item
                        <div className="text-gray-900">
                          <div className="rounded-sm text-green-500 pb-2">
                            <span className="text-red-500">[ {tgl[0]} ]</span> [ {detailPenjualan?.item?.kode_item} - {detailPenjualan?.item?.jenis_kategori?.nama_jenis} ]{' '}
                          </div>
                        </div>
                      </div>
                      <div className="border-b mt-1 text-base font-normal text-slate-600 flex justify-between px-5">
                        Berat
                        <div className="text-gray-900">
                          <div className="rounded-sm text-green-500 pb-2">{detailPenjualan?.item?.berat} Gram</div>
                        </div>
                      </div>
                      <div className="border-b mt-1 text-base font-normal text-slate-600 flex justify-between px-5">
                        Emas
                        <div className="text-gray-900">
                          <div className="rounded-sm text-green-500 pb-2">{detailPenjualan?.item?.karat} Karat</div>
                        </div>
                      </div>
                      <div className="border-b mt-1 text-base font-normal text-slate-600 flex justify-between px-5">
                        Harga Modal / gram{' '}
                        <span className="text-red-500 text-xs -ml-52">(* Harga modal saat diinput</span>
                        <div className="text-gray-900">
                          <div className="rounded-sm text-green-500 pb-2">
                            Rp{' '}
                            {numberWithCommas(
                              detailPenjualan?.item?.harga_modal == undefined ? '0' : detailPenjualan?.item?.harga_modal,
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="border-b mt-1 text-base font-normal text-slate-600 flex justify-between px-5">
                        Harga Jual / gram{' '}
                        <span className="text-red-500 text-xs -ml-52">(* Total harga jual dibagi berat</span>
                        <div className="text-gray-900">
                          <div className="rounded-sm text-green-500 pb-2">
                            Rp{' '}
                            {numberWithCommas(
                              detailPenjualan?.item?.harga_modal == undefined ? '0' : Math.round(harga_modal_jual),
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="border-b mt-1 text-base font-normal text-slate-600 flex justify-between px-5">
                        Total Harga
                        <div className="text-gray-900">
                          <div className="rounded-sm text-green-500 pb-2">
                            Rp{' '}
                            {numberWithCommas(
                              detailPenjualan?.total_harga == undefined ? '0' : detailPenjualan?.total_harga,
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="border-b mt-1 text-base font-normal text-slate-600 flex justify-between px-5">
                        Laba Penjualan{' '}
                        <span className="text-red-500 text-xs -ml-40">(* Jika minus (RUGI) dan jika plus (UNTUNG)</span>
                        <div className="text-gray-900">
                          <div className="rounded-sm text-green-500 pb-2">
                            Rp{' '}
                            {numberWithCommas(
                              detailPenjualan?.laba == undefined ? '0' : Math.round(detailPenjualan?.laba),
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 text-base font-normal text-slate-600  px-5">
                        Gambar
                        <div className="mt-2 overflow-hidden">
                          {/* {isOpen && (
                            <PhotoView src={
                              detailPenjualan?.item?.gambar1 === null
                                ? ''
                                : `${imageBaseUrl}${detailPenjualan?.item?.gambar1[0].asli}`
                            }>
                              <img src={
                                detailPenjualan?.item?.gambar1 === null
                                  ? ''
                                  : `${imageBaseUrl}${detailPenjualan?.item?.gambar1[0].asli}`
                              } alt="" width={'80px'}
                                height={'80px'} srcSet="" />
                            </PhotoView>
                          )} */}
                          {isOpen && (
                            <ImageComponent
                              onClick={() => setOpenImage(true)}
                              className={'max-w-[80px]  hover:scale-110 cursor-pointer'}
                              width={'80px'}
                              height={'80px'}
                              style={{ objectFit: 'cover' }}
                              src={
                                detailPenjualan?.item?.gambar1 === null
                                  ? ''
                                  : `${imageBaseUrl}${detailPenjualan?.item?.gambar1[0].asli}`
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 flex items-center justify-end gap-1">
                      <button
                        onClick={closeModal}
                        className="flex items-center bg-slate-500 p-2 font-light hover:bg-slate-600 transition duration-500 text-white"
                      >
                        Tutup
                      </button>
                      <ReactToPrint
                        trigger={() => (
                          <button className="flex items-center bg-green-500 p-2 font-light hover:bg-green-600 transition duration-500 text-white">
                            Print
                          </button>
                        )}
                        content={() => compSurat.current}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
