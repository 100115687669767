import { IconCalendarTime, IconReload } from '@tabler/icons-react';
import moment from 'moment';
import React from 'react';
import { Calendar } from 'react-date-range';

function SmTransaksiTertunda({
  summaryPenjualan,
  loadingDataPenjualan,
  cabangFilterSm,
  setIdCabangFilterPenjualan,
  setDateFilterPenjualan,
  reloadPenjualan,
  totalHargaPenjualan,
  totalBeratPenjualan,
  totalBijiPenjualan,
  setKaratFilterPenjualan,
}) {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  function handleSelect(date) {
    setDateFilterPenjualan(moment(date).format('DD-MM-YYYY'));
  }
  const average = Math.round(totalHargaPenjualan / totalBeratPenjualan);

  const karatOption = [
    { karat: 16 },
    { karat: 17 },
    { karat: 18 },
    { karat: 19 },
    { karat: 20 },
    { karat: 21 },
    { karat: 22 },
    { karat: 23 },
    { karat: 24 },
  ];

  return (
    <div className="">
      <div className="px-4 pt-2 pb-1 flex items-center justify-between bg-green-500/50">
        <h6 className="font-bold uppercase text-sm  text-slate-900">Penggarus</h6>
        <div className="flex items-center gap-3">
          <select
            className="p-1 cursor-pointer"
            name=""
            id=""
            onChange={(e) => setIdCabangFilterPenjualan(e.target.value)}
          >
            <option value=" ">Pilih Etalase</option>
            {cabangFilterSm.map((cbf) => (
              <option key={cbf.id} value={cbf.id}>
                {cbf.nama_cabang}
              </option>
            ))}
          </select>

          <select id="" onChange={(e) => setKaratFilterPenjualan(e.target.value)} className="p-1 cursor-pointer">
            <option value=" ">Pilih Karat</option>
            {karatOption.map((item, i) => (
              <option key={i} value={item.karat}>
                {item.karat}
              </option>
            ))}
          </select>

          <div className="dropdown dropdown-bottom">
            <div tabIndex={0} className="bg-orange-500 text-white p-1 rounded cursor-pointer">
              <IconCalendarTime size={20} />
            </div>
            <div tabIndex={0} className="dropdown-content menu -right-52">
              <Calendar size={20} onChange={handleSelect} date={new Date()} />
            </div>
          </div>

          {/* <div className="dropdown dropdown-end bg-white rounded-md p-2">
            <button tabIndex={0}>
              <IconCalendarEvent />
            </button>
            <div tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box ">
              <Calendar onChange={handleSelect} date={new Date()} />
            </div>
          </div> */}
          <button onClick={reloadPenjualan} className="bg-orange-500 text-white p-1 rounded">
            <IconReload size={20} />
          </button>
        </div>
      </div>
      <div className="p-2 overflow-x-auto">
        {loadingDataPenjualan ? (
          <div className=" font-semibold px-2 py-1 rounded-br-md rounded-bl-md flex items-center gap-3">
            <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    '.spinner_Wezc{transform-origin:center;animation:spinner_Oiah .75s step-end infinite}@keyframes spinner_Oiah{8.3%{transform:rotate(30deg)}16.6%{transform:rotate(60deg)}25%{transform:rotate(90deg)}33.3%{transform:rotate(120deg)}41.6%{transform:rotate(150deg)}50%{transform:rotate(180deg)}58.3%{transform:rotate(210deg)}66.6%{transform:rotate(240deg)}75%{transform:rotate(270deg)}83.3%{transform:rotate(300deg)}91.6%{transform:rotate(330deg)}100%{transform:rotate(360deg)}}',
                }}
              />
              <g className="spinner_Wezc">
                <circle cx={12} cy="2.5" r="1.5" opacity=".14" />
                <circle cx="16.75" cy="3.77" r="1.5" opacity=".29" />
                <circle cx="20.23" cy="7.25" r="1.5" opacity=".43" />
                <circle cx="21.50" cy={12.0} r="1.5" opacity=".57" />
                <circle cx="20.23" cy="16.75" r="1.5" opacity=".71" />
                <circle cx="16.75" cy="20.23" r="1.5" opacity=".86" />
                <circle cx={12} cy="21.5" r="1.5" />
              </g>
            </svg>
            Memuat data....
          </div>
        ) : (
          <table className="w-full border text-sm text-left rounded-md overflow-hidden">
            <thead className="text-xs sticky top-0 border-b uppercase bg-gray-50 rounded-md dark:bg-gray-700">
              <tr>
                <th>No</th>
                <th>Kategori</th>
                <th>biji</th>
                <th>berat</th>
                <th>Harga</th>
                <th>Rata - Rata</th>
              </tr>
            </thead>
            <tbody>
              {summaryPenjualan.map((sp, i) => {
                const rata_rata = Math.round(sp.total_harga_penjualan / sp.total_berat_penjualan);
                return (
                  <tr key={i} className="border-b">
                    <td>{i + 1}.</td>
                    <td>{sp.kategori}</td>
                    <td>{sp.jumlah_item_penjualan}</td>
                    <td>{sp.total_berat_penjualan}</td>
                    <td>
                      Rp {numberWithCommas(sp.total_harga_penjualan == undefined ? '0' : sp.total_harga_penjualan)}
                    </td>
                    <td>Rp {numberWithCommas(isNaN(rata_rata) ? 0 : rata_rata)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <hr />
        <div className="mt-2 border text-sm rounded-md p-1 overflow-x-auto scrollbar-none flex items-center">
          {/* <div className="font-semibold flex justify-between">JUMLAH :  </div> */}
          <div className="whitespace-nowrap">
            [ <span className="text-red-600">{totalBijiPenjualan}</span> biji ]&nbsp;
          </div>
          <div className="whitespace-nowrap ml-3">
            [ <span className="text-red-600">{totalBeratPenjualan}</span> gram ]&nbsp;
          </div>
          <div className="whitespace-nowrap ml-3">
            [{' '}
            <span className="text-red-600">
              Rp.{numberWithCommas(totalHargaPenjualan == undefined ? '0' : totalHargaPenjualan)}
            </span>
            ]&nbsp;
          </div>
          <div className="whitespace-nowrap ml-3">
            [ rata-rata{' '}
            <span className="text-red-600">
              Rp.
              {numberWithCommas(isNaN(average) ? 0 : average)}
              {/* {numberWithCommas(totalHargaPenjualan / totalHargaPenjualan == undefined ? '0' : Math.round( totalHargaPenjualan / totalBeratPenjualan ))} */}
            </span>{' '}
            ]
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmTransaksiTertunda;
