import { Dialog, Transition } from '@headlessui/react';
import { IconAlertTriangle } from '@tabler/icons-react';
import { Fragment, useState } from 'react';

export default function ConfirmationDeleteItem({
  isDelete,
  isOpenModalDelete,
  deleteBarang,
  setKeterangan,
  barangDelete,
  closeModaDelete,
}) {
  // console.log(barangDelete)
  return (
    <>
      <Transition appear show={isOpenModalDelete} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModaDelete}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center p-4 min-h-[440px]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden  bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="div"
                    className="text-red-500   flex justify-center font-medium leading-6 "
                  ></Dialog.Title>
                  <div>
                    <div className="bg-white  md:max-w-md ">
                      <div className="flex items-center gap-4">
                        <div className=" flex items-center justify-center w-16 h-16 ">
                          <IconAlertTriangle className="border rounded-full p-2" width={70} height={70} />
                        </div>
                        <div className="mt-4  md:ml-2  md:text-left">
                          <p className="font-bold ">{barangDelete.jenis_kategori?.nama_jenis}</p>
                          <p className="text-sm text-gray-700 mt-1">
                            Apakah Anda Yakin untuk <span className="text-red-500 font-bold">menghapus ?</span> Barang
                            dengan Kode <span className="text-red-500 font-bold">{barangDelete.kode_item}</span>
                          </p>
                          <textarea name="keterangan" className='border rounded border-slate-300 w-full px-2 py-3' placeholder='Tulis alasan kenapa barang ini dihapus' onChange={(e) => setKeterangan(e.target.value)} id=""></textarea>
                        </div>
                      </div>
                      <div className="text-right mt-2 gap-2  flex justify-end">
                      <button onClick={closeModaDelete} className="bg-slate-500  text-white px-4 py-2">
                          Batal
                        </button>
                        {barangDelete.is_ready && (
                          <button onClick={deleteBarang} className="bg-red-500  text-white px-4 py-2">
                            {isDelete ? 'Prosess ...' : 'Hapus'}
                          </button>
                        )}
                        
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
