import { Dialog, Transition } from '@headlessui/react';
import React, { useRef } from 'react';
import { Fragment } from 'react';
import ReactToPrint from 'react-to-print';
import QRCode from 'react-qr-code';

export default function PrintBandrol({ openPrintBandrol, selectedItems, closeModalPrintBandrol, setSelectedItems }) {
  let componentRef = useRef();
  const tipe_surat = localStorage.getItem('tipe_surat');
  return (
    <>
      <Transition appear show={openPrintBandrol} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModalPrintBandrol}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center p-4 min-h-[440px]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden bg-white p-2 print:border text-left align-middle shadow-xl print:shadow-none transition-all">
                  <Dialog.Title as="h3"></Dialog.Title>
                  <div className="mt-2">
                    {selectedItems.length === 0 ? (
                      <div>Silahkan Pilih Item Yang Mau di Print</div>
                    ) : (
                      <div>
                        {tipe_surat === '1' || tipe_surat === '3' || tipe_surat === '4' ? (
                          <div ref={(el) => (componentRef = el)} className="w-full ">
                            <div className="grid justify-end mr-[65px]">
                              <div>
                                {selectedItems.map((item) => (
                                  <div key={item.id} className="flex mt-[16px]">
                                    <div className="mb-[17px]">
                                      <QRCode size={42} value={item.kode_item == undefined ? '' : item.kode_item} />
                                    </div>
                                    <div className="ml-9 mb-[17px]">
                                      <div className="text-sm ">{item.kode_item}</div>
                                      <div className="text-sm font-bold tracking-tighter">
                                        {item.berat} g / {item.karat} k{' '}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div ref={(el) => (componentRef = el)} className="w-full ">
                            <div className="grid justify-end mr-[65px]">
                              <div className="pt-6">
                                {selectedItems.map((item) => (
                                  <div key={item.id} className="grid grid-cols-2 -mr-4 mt-4 ">
                                    <div className="mb-[17px]">
                                      <QRCode size={42} value={item.kode_item == undefined ? '' : item.kode_item} />
                                    </div>
                                    <div className="ml-1 mb-[17px]">
                                      <div className="text-sm ">{item.kode_item}</div>
                                      <div className="text-sm font-bold tracking-tighter">
                                        {item.berat} g / {item.karat} k{' '}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="mt-4 gap-2 flex justify-end print:invisible">
                    <button
                      type="button"
                      className="bg-slate-500 text-white font-semibold hover:bg-slate-600 transition duration-300 px-4 py-2"
                      onClick={closeModalPrintBandrol}
                    >
                      Batal
                    </button>
                    <ReactToPrint
                      trigger={() => (
                        <div>
                          <button className="bg-green-500 text-white font-semibold hover:bg-green-600 transition duration-300 px-4 py-2">
                            Print
                          </button>
                        </div>
                      )}
                      content={() => componentRef}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
