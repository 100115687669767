import { Dialog, Transition } from '@headlessui/react';
import { useEffect } from 'react';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAuthorizationHeader, http } from '../../config/http';
import LoadingIndicator from '../LoadingIndicator';

export default function AddJenisBarang({ isOpen, closeModal }) {
  const navigate = useNavigate();
  const [kategori, setKategori] = useState([]);

  const [formData, setFormData] = useState({
    kategori_id: '',
    nama_jenis: '',
  });

  const [loading, setLoading] = useState(false);

  function handleChangeJenisBarang(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  // console.log(formData.kategori_id, formData.nama_jenis)
  useEffect(() => {
    async function getKategori() {
      await http
        .get('/kategori', {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setKategori(res.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
    }
    getKategori();
  }, [navigate]);
  const { kategori_id, nama_jenis } = formData;
  // console.log({ kategori_id, nama_jenis })

  async function handleSubmitJenisBarang(e) {
    e.preventDefault();
    const dataForm = {
      kategori_id,
      nama_jenis,
    };
    setLoading(true);
    try {
      const response = await http.post(`/jenis`, dataForm, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      setLoading(false);
      toast.success(response?.data?.message, { autoClose: 600, });
      closeModal();
      return response.data.message;
    } catch (error) {
      console.log(error);
      toast.warning(error?.response?.data?.message, { autoClose: 600, });
      if (error.response.status === 401) {
        navigate('/login');
      }
    }
    setLoading(false);
  }
  function fakeModal() { }
  return (
    <>
    {loading && <LoadingIndicator/>}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={fakeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-[440px] items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-xl mb-5 font-medium leading-6 text-gray-900">
                    Tambah Jenis Kategori
                  </Dialog.Title>
                  <div className="mt-2">
                    <form onSubmit={handleSubmitJenisBarang}>
                      <div className="mb-6">
                        <label
                          htmlFor="kategori"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Pilih Kategori
                        </label>
                        <select
                          onChange={handleChangeJenisBarang}
                          name="kategori_id"
                          value={formData.kategori_id}
                          className="select select-bordered rounded-none w-full"
                        >
                          <option value="">Pilih Kategori</option>
                          {kategori.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.nama_kategori == undefined ? '' : item.nama_kategori}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-6">
                        <label htmlFor="berat" className="block mb-2 text-sm  font-medium text-gray-900 dark:text-white">
                          Nama Jenis
                        </label>
                        <input
                          onChange={handleChangeJenisBarang}
                          type="text"
                          value={formData.nama_jenis}
                          id="nama_jenis"
                          name="nama_jenis"
                          placeholder="Masukkan Nama Jenis Barang"
                          // value={formData.berat}
                          // onChange={handleOnChange}
                          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                        />
                      </div>
                      <div className="flex justify-end gap-2">
                        <button
                          type="reset"
                          onClick={closeModal}
                          className="text-white  bg-slate-600 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 font-medium  text-sm w-full sm:w-auto px-5 py-2 text-center dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800"
                        >
                          Tutup
                        </button>
                        <button
                          type="submit"
                          className="text-white  bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium  text-sm w-full sm:w-auto px-5 py-2 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                        >
                          {loading ? 'Prosess..' : 'Simpan'}
                        </button>

                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
