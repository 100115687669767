import React, { useEffect, useState } from 'react';
import Layouts from '../layouts/Layouts';
import { DinamicTitle } from '../utils/DinamicTitle';
import { getAuthorizationHeader, http } from '../config/http';
import TambahPengeluaran from './Modal/TambahPengeluaran';
import { IconChevronLeft, IconChevronRight, IconEdit, IconPlus, IconRefresh, IconTrash } from '@tabler/icons-react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import { IconCalendarTime } from '@tabler/icons-react';
import { IconArrowsRightLeft } from '@tabler/icons-react';
import ConfirmationProsess from './Modal/ConfirmationProsess';
import LayoutsKasir from '../layouts/LayoutsKasir';
import DetailTransaksiLuar from './Transaksi/DetailTransaksiLuar';
import ModalHapusPengeluaran from './Modal/ModalHapusPengeluaran';

function Pengeluaran() {
  DinamicTitle('Pengeluaran || Komaladewi');
  let [isOpenTambahPengeluaran, setIsOpenTambahPengeluaran] = useState(false);
  const navigate = useNavigate();
  function closeModalTambahPengeluaran() {
    setIsOpenTambahPengeluaran(false);
  }

  function openModalTambahPengeluaran() {
    setIsOpenTambahPengeluaran(true);
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  const [pengeluaranHariIni, setPengeluaranHariIni] = useState([]);
  const [loadingPengeluaranHariIni, setLoadingPengeluaranHariIni] = useState(false);
  const [isSubmitPengeluaran, setIsSubmitPengeluaran] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const awal = moment(startDate).format('DD-MM-YYYY');
  const akhir = moment(endDate).format('DD-MM-YYYY');

  const [isOpenModalProses, setIsOpenModalProses] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [submitProsess, setSubmitProsess] = useState(false);

  const [cabang, setCabang] = useState([]);
  // console.log('cabang :', cabang);

  useEffect(() => {
    async function getCabang() {
      await http
        .get('/cabang', {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setCabang(res.data.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          }
        });
    }
    getCabang();
  }, []);
  const [isProses, setIsProses] = useState(0);
  const [cabangId, setCabangId] = useState('');
  async function getPengeluaranHariIni() {
    setLoadingPengeluaranHariIni(true);
    await http
      .get(
        `cashflow?page=${currentPage}${startDate === null && endDate === null ? `` : `&tgl_awal=${awal}&tgl_akhir=${akhir}`
        }${cabangId === '' ? '' : `&cabang=${cabangId}`}${isProses === '' ? '' : `&is_proses=${isProses}`}`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        },
      )
      .then((res) => {
        setLastPage(res.data.data.last_page);
        setCurrentPage(res.data.data.current_page);
        setPengeluaranHariIni(res.data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoadingPengeluaranHariIni(false);
  }
  useEffect(() => {
    getPengeluaranHariIni();
  }, [isSubmitPengeluaran, currentPage, awal, akhir, isProses, cabangId, isProses, submitProsess]);
  function reloadPengeluaranHariIni(params) {
    let awal = null;
    let akhir = null;
    let isProsess = '';
    getPengeluaranHariIni();
    setEndDate(akhir);
    setStartDate(awal);
    setIsProses(isProses);
    setCabangId('');
  }

  const [formData, setFormData] = useState({
    title: '',
    tipe: '',
    keterangan: '',
    cabang_id: '',
  });
  const [nominal, setNominal] = useState(0);

  // console.log({formData, nominal})

  function handleOnChange(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  const { title, tipe, cabang_id, keterangan } = formData;

  async function handleSubmitPengeluaran(e) {
    e.preventDefault();
    setLoadingSubmit(true);
    const data = { title, tipe, cabang_id, nominal, keterangan };
    try {
      const response = await http.post(`/cashflow`, data, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      // console.log(response.data.message)
      toast.success(response.data.message, { autoClose: 600, });
      closeModalTambahPengeluaran();
      setTimeout(() => {
        setIsSubmitPengeluaran(true);
      }, 2000);
      setLoadingSubmit(false);
      return response.data;
    } catch (error) {
      // console.log('error :', error.response.status);
      if (error.response?.status === 401) {
        navigate('/login');
      } else if (error.response?.status === 422) {
        toast.warning('Lengkapi input data', { autoClose: 600, });
      } else if (error.code === 'ERR_NETWORK') {
        toast.warning('Periksa jaringan internet', { autoClose: 600, });
      }
      setLoadingSubmit(false);
    }
    setIsSubmitPengeluaran(false);
    setLoadingSubmit(false);
  }
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  function closeModalDetail() {
    setIsOpenDetail(false)
  }
  const [detailPengeluaran, setDetailPengeluaran] = useState([]);
  const [loading, setLoading] = useState(false);
  async function getDetailPengeluaran(id) {
    setLoading(true);
    await http.get(`/cashflow/${id}`, {
      headers: { Authorization: getAuthorizationHeader() },
    }).then((res) => {
      setDetailPengeluaran(res.data.data);
      setIsOpenDetail(true)
    }).catch((error) => {
      console.log(`error : ${error}`);
    })
    setLoading(false);
  }

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  };
  function handleSelect(date) {
    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate);
  }
  async function submitProses(e) {
    e.preventDefault();
    setIsLoadingSubmit(true);
    await http
      .post(`submit_cashflow`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        // console.log(res.data);
        closeModalProses();
        toast.success(res.data.message, { autoClose: 450 });
        setSubmitProsess(true);
        setTimeout(() => {
          setSubmitProsess(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status == 404) {
          toast.warning('Tidak ada data', { autoClose: 600, });
        } else if (err.response.status == 401) {
          toast.warning(err.response.data.message, { autoClose: 600, });
        }
      });
    setIsLoadingSubmit(false);
  }

  function closeModalProses() {
    setIsOpenModalProses(false);
  }
  function openModalProses() {
    setIsOpenModalProses(true);
  }
  const [isOpenModalHapus, setIsOpenModalHapus] = useState(false);
  const [idHapus, setIdHapus] = useState(null)
  function closeModalHapus() {
    setIsOpenModalHapus(false)
  }
  function openModalHapus(id){
    setIsOpenModalHapus(true)
    setIdHapus(id)
  }


  const role = localStorage.getItem('role');
  if (role == 'kasir') {
    return (
      <LayoutsKasir
        title={'Pengeluaran Harian'}
        compFilter={
          <div className="flex gap-1">
            <button
              onClick={reloadPengeluaranHariIni}
              className="hover:bg-slate-700 bg-slate-600 text-white p-2 rounded-md "
            >
              <IconRefresh />
            </button>
            <div className="dropdown dropdown-bottom ">
              <div tabIndex={0} className="bg-orange-500 text-white p-2 rounded cursor-pointer">
                <IconCalendarTime />
              </div>
              <div tabIndex={0} className="dropdown-content menu right-[45px] ">
                <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
              </div>
            </div>
          </div>
        }
      >

        <div className="sm:mx-4 mx-2 p-2 mt-2 rounded-md">
          {/* The button to open modal */}
          {loading && (
            <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700/20 opacity-75 flex flex-col items-center justify-center">
              <div className="w-12 h-12  rounded-full animate-spin border-4 border-dashed border-green-500 border-t-transparent"></div>
            </div>
          )}
          <TambahPengeluaran
            cabang={cabang}
            handleSubmitPengeluaran={handleSubmitPengeluaran}
            loadingSubmit={loadingSubmit}
            nominal={nominal}
            setNominal={setNominal}
            handleOnChange={handleOnChange}
            closeModalTambahPengeluaran={closeModalTambahPengeluaran}
            isOpenTambahPengeluaran={isOpenTambahPengeluaran}
          />
          <div className="flex items-center justify-between gap-2">
            <div className="flex gap-2">
              <select
                onChange={(e) => setCabangId(e.target.value)}
                className="border p-2 rounded-md cursor-pointer shadow-md"
              >
                <option className="pt-6" value="">
                   Etalase
                </option>
                {cabang.map((cb) => (
                  <option key={cb.id} value={cb.id}>
                    {cb.nama_cabang}
                  </option>
                ))}
              </select>
              <select
                onChange={(e) => setIsProses(e.target.value)}
                className="border p-2 rounded-md cursor-pointer shadow-md"
              >
                <option className="pt-6" value="">
                  Status
                </option>
                <option className="pt-6" value={0}>
                  Belum diproses
                </option>
                <option className="pt-6" value={1}>
                  Sudah diproses
                </option>
              </select>
            </div>
            <div className="flex items-center gap-2">
              <button
                className="flex items-center p-2 bg-orange-600 rounded-md text-white hover:bg-orange-700"
                onClick={openModalProses}
              >
                <IconArrowsRightLeft size={20} />
                Proses
              </button>
              <div>
                <ConfirmationProsess
                  submitProses={submitProses}
                  closeModaProses={closeModalProses}
                  isOpenModalProses={isOpenModalProses}
                  isLoadingSubmit={isLoadingSubmit}
                />
              </div>
              <button
                className="p-2 flex items-center bg-green-600 rounded-md text-white hover:bg-green-700"
                onClick={openModalTambahPengeluaran}
              >
                <IconPlus size={20} />
                Tambah
              </button>
            </div>
          </div>
          {loadingPengeluaranHariIni ? (
            <div className="flex  items-center justify-center ">
              <div className=" font-semibold px-2 py-1 rounded-br-md rounded-bl-md flex items-center gap-3">
                <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        '.spinner_Wezc{transform-origin:center;animation:spinner_Oiah .75s step-end infinite}@keyframes spinner_Oiah{8.3%{transform:rotate(30deg)}16.6%{transform:rotate(60deg)}25%{transform:rotate(90deg)}33.3%{transform:rotate(120deg)}41.6%{transform:rotate(150deg)}50%{transform:rotate(180deg)}58.3%{transform:rotate(210deg)}66.6%{transform:rotate(240deg)}75%{transform:rotate(270deg)}83.3%{transform:rotate(300deg)}91.6%{transform:rotate(330deg)}100%{transform:rotate(360deg)}}',
                    }}
                  />
                  <g className="spinner_Wezc">
                    <circle cx={12} cy="2.5" r="1.5" opacity=".14" />
                    <circle cx="16.75" cy="3.77" r="1.5" opacity=".29" />
                    <circle cx="20.23" cy="7.25" r="1.5" opacity=".43" />
                    <circle cx="21.50" cy={12.0} r="1.5" opacity=".57" />
                    <circle cx="20.23" cy="16.75" r="1.5" opacity=".71" />
                    <circle cx="16.75" cy="20.23" r="1.5" opacity=".86" />
                    <circle cx={12} cy="21.5" r="1.5" />
                  </g>
                </svg>
                Memuat data....
              </div>
            </div>
          ) : (
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 mt-2 ">
              <div className="inline-block min-w-full rounded-md overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-5 py-2 border-b-2 whitespace-nowrap border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        No
                      </th>
                      <th className="px-5 py-2 border-b-2 whitespace-nowrap border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Title
                      </th>
                      <th className="px-5 py-2 border-b-2 whitespace-nowrap border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Nominal
                      </th>
                      <th className="px-5 py-2 border-b-2 whitespace-nowrap border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Tipe
                      </th>
                      <th className="px-5 py-2 border-b-2 whitespace-nowrap border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Keterangan
                      </th>
                      <th className="px-5 py-2 border-b-2 whitespace-nowrap border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Tanggal
                      </th>
                      <th className="px-5 py-2 border-b-2 whitespace-nowrap border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Diproses
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pengeluaranHariIni.map((phi, i) => (
                      <tr
                        key={phi.id}
                        className="bg-white border-b dark:bg-gray-800 cursor-pointer dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      >
                        <td className="px-5 py-2 border-b border-gray-200  text-sm">
                          <span className="text-gray-900  whitespace-nowrap">{i + 1}</span>
                        </td>
                        <td className="px-5 py-2 border-b border-gray-200  text-sm">
                          <span className="text-gray-900  whitespace-nowrap">{phi.title}</span>
                        </td>
                        <td className="px-5 py-2 border-b border-gray-200  text-sm">
                          <span className="text-gray-900  whitespace-nowrap">Rp.{numberWithCommas(phi.nominal)}</span>
                        </td>
                        <td className="px-5 py-2 border-b border-gray-200  text-sm">
                          <span className="text-gray-900  whitespace-nowrap">{phi.tipe}</span>
                        </td>
                        <td className="px-5 py-2 border-b border-gray-200  text-sm">
                          <span className="text-gray-900  whitespace-nowrap">{phi.keterangan}</span>
                        </td>
                        <td className="px-5 py-2 border-b border-gray-200  text-sm">
                          <span className="text-gray-900  whitespace-nowrap">{phi.created_at}</span>
                        </td>
                        <td className="px-5 py-2 border-b border-gray-200  text-sm">
                          <span className="text-gray-900  whitespace-nowrap">
                            {phi.is_proses == 0 ? (
                              <span className="border bg-red-500 p-1 text-white rounded">belum</span>
                            ) : (
                              <span className="bg-green-500 p-1 text-white rounded">proses</span>
                            )}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        <div className="bg-white bottom-0 flex justify-between left-0 py-4 w-full absolute">
          <div className="flex justify-end w-full mx-5">
            <div className="items-center flex justify-center space-y-2 text-xs sm:space-y-0 sm:space-x-3 sm:flex">
              <span className="block">
                Page {currentPage} of {lastPage}{' '}
              </span>
              <div className="space-x-1">
                <button
                  onClick={() => setCurrentPage(currentPage - 1)}
                  title="previous"
                  disabled={currentPage === 1 ? true : false}
                  type="button"
                  className="inline-flex items-center disabled:cursor-not-allowed justify-center w-8 h-8 py-0 border rounded-md shadow"
                >
                  <IconChevronLeft />
                </button>
                <button
                  onClick={() => setCurrentPage(currentPage + 1)}
                  title="next"
                  type="button"
                  className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow"
                >
                  <IconChevronRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </LayoutsKasir>
    );
  } else {
    return (
      <Layouts
        title={'Pengeluaran Harian'}
        compFilter={
          <div className="flex gap-1">
            <button
              onClick={reloadPengeluaranHariIni}
              className="hover:bg-slate-700 bg-slate-600 text-white p-2  "
            >
              <IconRefresh />
            </button>
            <div className="dropdown dropdown-bottom ">
              <div tabIndex={0} className="bg-orange-500 text-white p-2  cursor-pointer">
                <IconCalendarTime />
              </div>
              <div tabIndex={0} className="dropdown-content menu right-[45px] ">
                <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
              </div>
            </div>
          </div>
        }
      >
        {loading && (
          <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700/20 opacity-75 flex flex-col items-center justify-center">
            <div className="w-12 h-12  rounded-full animate-spin border-4 border-dashed border-green-500 border-t-transparent"></div>
          </div>
        )}
        <ModalHapusPengeluaran
          idHapus={idHapus}
          closeModaLogout={closeModalHapus}
          isOpenModalLogout={isOpenModalHapus}
          getPengeluaranHariIni={getPengeluaranHariIni}
        />
        <DetailTransaksiLuar
          getPengeluaranHariIni={getPengeluaranHariIni}
          closeModal={closeModalDetail}
          label={'Detail Pengeluaran'}
          detailPenjualan={detailPengeluaran}
          isOpen={isOpenDetail}
        />
        <div className="">
          {/* The button to open modal */}
          <TambahPengeluaran
            cabang={cabang}
            handleSubmitPengeluaran={handleSubmitPengeluaran}
            loadingSubmit={loadingSubmit}
            nominal={nominal}
            setNominal={setNominal}
            handleOnChange={handleOnChange}
            closeModalTambahPengeluaran={closeModalTambahPengeluaran}
            isOpenTambahPengeluaran={isOpenTambahPengeluaran}
          />
          <div className="flex items-center mt-0.5 bg-white justify-between gap-2">
            <div className="flex gap-0.5">
              <select
                onChange={(e) => setCabangId(e.target.value)}
                className="border p-2 cursor-pointer "
              >
                <option className="pt-6" value="">
                   ETALASE
                </option>
                {cabang.map((cb) => (
                  <option key={cb.id} value={cb.id}>
                    {cb.nama_cabang}
                  </option>
                ))}
              </select>
              <select
                onChange={(e) => setIsProses(e.target.value)}
                className="border p-1 cursor-pointer shadow-md"
              >
                <option className="pt-6" value="">
                  FILTER STATUS
                </option>
                <option className="pt-6" value={0}>
                  Belum diproses
                </option>
                <option className="pt-6" value={1}>
                  Telah diproses
                </option>
              </select>
            </div>
            <div className="flex items-center gap-0.5">
              <button
                className="flex items-center text-sm px-2 py-1 bg-orange-600 text-white hover:bg-orange-700"
                onClick={openModalProses}
              >
                <IconArrowsRightLeft size={20} />
                &nbsp;Proses
              </button>
              <div>
                <ConfirmationProsess
                  submitProses={submitProses}
                  closeModaProses={closeModalProses}
                  isOpenModalProses={isOpenModalProses}
                  isLoadingSubmit={isLoadingSubmit}
                />
              </div>
              <button
                className="px-2 py-1 flex items-center text-sm bg-green-600 text-white hover:bg-green-700"
                onClick={openModalTambahPengeluaran}
              >
                <IconPlus size={20} />
                &nbsp;Tambah
              </button>
            </div>
          </div>
          {loadingPengeluaranHariIni ? (
            <div className="flex  items-center justify-center ">
              <div className=" font-semibold px-2 py-1 rounded-br-md rounded-bl-md flex items-center gap-3">
                <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        '.spinner_Wezc{transform-origin:center;animation:spinner_Oiah .75s step-end infinite}@keyframes spinner_Oiah{8.3%{transform:rotate(30deg)}16.6%{transform:rotate(60deg)}25%{transform:rotate(90deg)}33.3%{transform:rotate(120deg)}41.6%{transform:rotate(150deg)}50%{transform:rotate(180deg)}58.3%{transform:rotate(210deg)}66.6%{transform:rotate(240deg)}75%{transform:rotate(270deg)}83.3%{transform:rotate(300deg)}91.6%{transform:rotate(330deg)}100%{transform:rotate(360deg)}}',
                    }}
                  />
                  <g className="spinner_Wezc">
                    <circle cx={12} cy="2.5" r="1.5" opacity=".14" />
                    <circle cx="16.75" cy="3.77" r="1.5" opacity=".29" />
                    <circle cx="20.23" cy="7.25" r="1.5" opacity=".43" />
                    <circle cx="21.50" cy={12.0} r="1.5" opacity=".57" />
                    <circle cx="20.23" cy="16.75" r="1.5" opacity=".71" />
                    <circle cx="16.75" cy="20.23" r="1.5" opacity=".86" />
                    <circle cx={12} cy="21.5" r="1.5" />
                  </g>
                </svg>
                Memuat data ....
              </div>
            </div>
          ) : (
            <div className="mt-0.5">
              <div className="inline-block border-t min-w-full overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-5 py-1 border-r border-b-2 whitespace-nowrap border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        No
                      </th>
                      <th className="px-5 py-1 border-r border-b-2 whitespace-nowrap border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Title
                      </th>
                      <th className="px-5 py-1 border-r border-b-2 whitespace-nowrap border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Nominal
                      </th>
                      <th className="px-5 py-1 border-r border-b-2 whitespace-nowrap border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Tipe
                      </th>
                      <th className="px-5 py-1 border-r border-b-2 whitespace-nowrap border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Keterangan
                      </th>
                      <th className="px-5 py-1 border-r border-b-2 whitespace-nowrap border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Tanggal
                      </th>
                      {/* <th className="px-5 py-1 border-r border-b-2 whitespace-nowrap border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Diproses
                      </th> */}
                      <th className="px-5 py-1 border-r border-b-2 whitespace-nowrap border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        Aksi
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pengeluaranHariIni.map((phi, i) => {
                      return (
                        <tr
                          key={phi.id}
                          className="bg-white border-b dark:bg-gray-800 cursor-pointer dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                        >
                          <td className="px-5 py-1 border-r border-b border-gray-200  text-sm">
                            <span className="text-gray-900  whitespace-nowrap">{i + 1}.</span>
                          </td>
                          <td className="px-5 py-1 border-r border-b border-gray-200  text-sm">
                            <span className="text-gray-900  whitespace-nowrap">{phi.title}</span>
                          </td>
                          <td className="px-5 py-1 border-r border-b border-gray-200  text-sm">
                            <span className="text-gray-900  whitespace-nowrap">Rp.{numberWithCommas(phi.nominal)}</span>
                          </td>
                          <td className="px-5 py-1 border-r border-b border-gray-200  text-sm">
                            <span className="text-gray-900  whitespace-nowrap">{phi.tipe}</span>
                          </td>
                          <td className="px-5 py-1 border-r border-b border-gray-200  text-sm">
                            <span className="text-gray-900  whitespace-nowrap">{phi.keterangan}</span>
                          </td>
                          <td className="px-5 py-1 border-r border-b border-gray-200  text-sm">
                            <span className="text-gray-900  whitespace-nowrap">{phi.created_at}</span>
                          </td>
                          {/* <td className="px-5 py-1 border-r border-b border-gray-200  text-sm">
                            <span className="text-gray-900  whitespace-nowrap">
                              {phi.is_proses == 0 ? (
                                <span className="border bg-orange-500 p-1 text-white">belum</span>
                              ) : (
                                <span className="bg-green-500 p-1 text-white">proses</span>
                              )}
                            </span>
                          </td> */}
                          {phi.is_proses === 0 ? (
                            <td className="px-5 py-1 border-r border-b flex gap-2 border-gray-200  text-sm">
                              <button
                                onClick={() => getDetailPengeluaran(phi.id)}
                                className="bg-blue-500 p-1 text-white "
                              >
                                <IconEdit className="w-5 h-5" />
                              </button>
                              <button onClick={() => openModalHapus(phi.id)} className="bg-red-500 p-1 text-white ">
                                <IconTrash className="w-5 h-5" />
                              </button>
                            </td>
                          ) : (
                            <span className='text-green-500'>Telah diproses</span>
                          )}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        <div className="bg-white bottom-0 flex justify-between left-0 py-4 w-full absolute">
          <div className="flex justify-end w-full mx-5">
            <div className="items-center flex justify-center space-y-2 text-xs sm:space-y-0 sm:space-x-3 sm:flex">
              <span className="block">
                Page {currentPage} of {lastPage}{' '}
              </span>
              <div className="space-x-1">
                <button
                  onClick={() => setCurrentPage(currentPage - 1)}
                  title="previous"
                  disabled={currentPage === 1 ? true : false}
                  type="button"
                  className="inline-flex items-center disabled:cursor-not-allowed justify-center w-8 h-8 py-0 border rounded-md shadow"
                >
                  <IconChevronLeft />
                </button>
                <button
                  onClick={() => setCurrentPage(currentPage + 1)}
                  title="next"
                  type="button"
                  className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow"
                >
                  <IconChevronRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Layouts>
    );
  }
}

export default Pengeluaran;
