import React from 'react'
import QRCode from 'react-qr-code';
import { imageBaseUrl } from '../../config/http';

const SuratToko = ({ detailPenjualan, isOpen, isTertunda }) => {
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return (
        <div className=" ">
            <div className="flex flex-col items-end">
                <div className="mt-[27px] mr-[13px] w-[329px] h-[91px] px-2">
                    <div className="flex justify-end">
                        <div className="w-[210px] h-[13px]  text-[12px]">
                            {isTertunda ? (
                                <div className=" text-sm">{isOpen ? `${detailPenjualan.tanggal_surat.split(' ')[0]}` : ``}</div>
                            ) : (
                                <div className=" text-sm">{isOpen ? `${detailPenjualan.created_at.split(' ')[0]}` : ``}</div>
                            )}
                        </div>
                    </div>
                    <div className="flex justify-end mt-[21px]">
                        <div className="w-[191px] h-[13px]  text-[12px]"></div>
                    </div>
                    <div className="flex ml-[85px] mt-[13px]">
                        <div className="w-[132px] h-[13px]  text-[13px] font-bold">
                            <div className="text-[15px]">{detailPenjualan.item?.kode_item} </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-[40px] ml-[100px] flex">
                <div className="h-[125px]  text-[11px] font-bold p-3">
                    <div>
                        {isOpen ? (
                            <img
                                width={150}
                                height={180}
                                style={{ maxHeight: '260px', maxWidth: '380px' }}
                                src={
                                    detailPenjualan.item?.gambar1 === null ? '' : `${imageBaseUrl}${detailPenjualan.item?.gambar1[0].asli}`
                                }
                            />
                        ) : (
                            <div className="ml-[20px] w-[120px] h-[120px] mb-2"></div>
                        )}
                    </div>
                </div>
                <div className="h-[102px] mt-[23px]   w-[224px] text-[11px] font-bold p-3">
                    <h2 className="font-semibold text-sm ">
                        {detailPenjualan.item?.jenis_kategori.nama_jenis}
                    </h2>
                </div>
                <div className="h-[102px] mt-[23px]   w-[86px] text-[11px] font-bold pt-3 ">
                    <h2 className="font-semibold text-sm">{detailPenjualan.item?.berat}</h2>

                </div>
                <div className="h-[102px] mt-[23px]   w-[64px] text-[11px] font-bold p-3">
                    <h2 className="font-semibold whitespace-nowrap text-sm">{detailPenjualan.item?.karat}</h2>

                </div>
                <div className="h-[102px] mt-[23px]   w-[108px] text-[11px] font-bold pl-5 pt-3">
                    <div className="">
                        <QRCode value={detailPenjualan.kode_trx === undefined ? 'unkwow' : detailPenjualan.kode_trx} size={60} />
                    </div>
                </div>
                <div>
                    <div className="h-[102px] mt-[23px]   w-[155px] text-[11px] font-bold p-3">
                        <h2 className="font-semibold text-sm ml-[3px] whitespace-nowrap">
                            Rp {numberWithCommas(detailPenjualan.total_harga == undefined ? '0' : detailPenjualan.total_harga)}
                        </h2>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SuratToko