import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';

export default function DetailSaldoKas({ closeModal, detailSaldoKas, isOpen }) {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center p-4 min-h-[440px]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-xl font-medium leading-6 text-gray-900">
                    Detail Saldo KAS
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="border rounded">
                      <div className="p-1 border-b bg-orange-500/10 text-slate-600">
                        <h2>Detail Transaksi</h2>
                      </div>
                      <div className="py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                        Kode Faktur
                        <p className="text-green-500">{detailSaldoKas.kode_trx}</p>
                      </div>
                      <hr className="mx-3" />
                      <div className="py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                        Nominal
                        <p className="text-green-500">
                          Rp.{numberWithCommas(detailSaldoKas.nominal == undefined ? '0' : detailSaldoKas.nominal)}
                        </p>
                      </div>
                      <hr className="mx-3" />
                      <div className=" py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                        Saldo Akhir
                        <p className="text-green-500">
                          Rp.
                          {numberWithCommas(detailSaldoKas.saldo_akhir == undefined ? '0' : detailSaldoKas.saldo_akhir)}
                        </p>
                      </div>
                      <hr className="mx-3" />
                      <div className=" py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                        Tanggal Transaksi
                        <p className="text-green-500">{detailSaldoKas.tanggal}</p>
                      </div>
                      <hr className="mx-3" />
                      <div className=" py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                        Tipe
                        <p className="text-green-500">{detailSaldoKas.tipe}</p>
                      </div>
                      <hr className="mx-3" />
                      <div className=" py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                        Keterangan
                        <p className="text-green-500">{detailSaldoKas.keterangan}</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 flex justify-end">
                    <button
                      type="button"
                      className="text-white  bg-slate-600 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 font-medium text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800"
                      onClick={closeModal}
                    >
                      Tutup
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
