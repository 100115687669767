import { Dialog, Transition } from '@headlessui/react';
import { useEffect } from 'react';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuthorizationHeader, http, imageBaseUrl } from '../../config/http';
import { IconX } from '@tabler/icons-react';
import ImageComponent from '../../utils/ImageComponent';

export default function EditTrxPembelian({
  isOpenEditTrx,
  submitPembelian,
  editTrxPembelian,
  setKarat,
  setKondisiBarang,
  berat,
  harga_beli,
  setHargaBeli,
  setJenisKategoriIdBase,
  setIsChange,
  setBerat,
  idEditPembelian,
  is_change_harga,
  closeModalEditTrx,
  karat,
  gambar,
  setGambar,
  isSelected,
  setIsSelected,
}) {
  // console.log('idEditPembelian::: ', idEditPembelian.gambar[0].asli);
  const [openImage, setOpenImage] = useState(false);
  const [load, setLoad] = useState(false);

  function fake(params) {}
  const [jenisKategori, setJenisKategori] = useState([]);
  const [selectImage, setSelectImage] = useState([]);

  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    setIsSelected(true);
    if (selectImage.length < 1) {
      const reader1 = new FileReader();
      reader1.addEventListener('load', function () {
        setGambar(reader1.result.split(',')[1]);
      });
      reader1.readAsDataURL(selectedFiles[0]);
    }
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    // setSelectImage((previousImages) => previousImages.concat(imagesArray));
    setSelectImage(imagesArray);
    // FOR BUG IN CHROME
    event.target.value = '';
  };

  const navigate = useNavigate();
  const [kategori, setKategori] = useState([]);
  useEffect(() => {
    async function getKategori() {
      await http
        .get('/kategori', {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          // console.log('res::: ', res);
          setKategori(res.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
    }
    getKategori();
  }, [navigate]);

  async function selectJenisKategori(id) {
    await http
      .get(`/jenis/kategori/${id}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        setJenisKategori(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleCheckbox() {
    setIsChange(!is_change_harga);
  }

  useEffect(() => {
    if (isOpenEditTrx === false) {
      setIsChange(false);
    }
  }, [isOpenEditTrx]);
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const karatOption = [
    { karat: 16 },
    { karat: 17 },
    { karat: 18 },
    { karat: 19 },
    { karat: 20 },
    { karat: 21 },
    { karat: 22 },
    { karat: 23 },
    { karat: 24 },
  ];

  const kondisiBarangOption = [{ kondisi: 'Ready Jual' }, { kondisi: 'Service' }, { kondisi: 'Rongsok' }];

  return (
    <>
      <Transition appear show={isOpenEditTrx} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={fake}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center p-4 min-h-[440px]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Edit Transaksi Pembelian : &nbsp;
                    <span className="text-green-700 font-semibold ">{idEditPembelian.kode_trx}</span>
                  </Dialog.Title>
                  {openImage && isOpenEditTrx && (
                    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700/20  flex flex-col items-center justify-center">
                      <div
                        onClick={() => setOpenImage(false)}
                        className={'absolute top-52 right-7 lg:top-16 lg:right-[280px] md:right-[120px] md:top-28 '}
                      >
                        <div className="bg-white p-2 rounded-full cursor-pointer hover:bg-white/50">
                          <IconX />
                        </div>
                      </div>
                      <div>
                        {!load && (
                          <div className="max-w-[48px] max-h-[48px]  rounded-full animate-spin border-4 border-dashed border-green-500 border-t-transparent"></div>
                        )}
                        <img
                          onLoad={() => setLoad(true)}
                          width={''}
                          className={'lg:w-[700px] md:w-[500px] w-[350px] scale-50'}
                          height={''}
                          src={
                            idEditPembelian.gambar === null ? '' : `${imageBaseUrl}${idEditPembelian.gambar[0].asli}`
                          }
                        />
                      </div>
                    </div>
                  )}
                  <form action="" onSubmit={editTrxPembelian}>
                    <div className="mb-6">
                      <label
                        htmlFor="kategori"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Pilih Kategori
                        <span className="text-red-500 ">
                          ({idEditPembelian.jenis_kategori?.kategori?.nama_kategori})
                        </span>
                      </label>
                      <select
                        onChange={(e) => selectJenisKategori(e.target.value)}
                        name="KategoriName"
                        className="select select-bordered w-full"
                      >
                        <option value="">Pilih Kategori</option>
                        {kategori.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.nama_kategori}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-6">
                      <label
                        htmlFor="kategori"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Pilih Jenis
                        <span className="text-red-500 ">( {idEditPembelian.jenis_kategori?.nama_jenis} )</span>
                      </label>
                      <select
                        onChange={(e) => setJenisKategoriIdBase(e.target.value)}
                        // name="jenis_kategori_id"
                        className="select select-bordered w-full"
                      >
                        <option value="">Pilih Jenis</option>
                        {jenisKategori.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.nama_jenis}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mt-2">
                      <div className="mb-6">
                        <label htmlFor="berat" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          Berat
                        </label>
                        <input
                          type="number"
                          id="berat"
                          name="berat"
                          autoComplete="off"
                          defaultValue={berat || ''}
                          onChange={(e) => setBerat(e.target.value)}
                          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                        />
                        {/* <input type="number" defaultValue={idEditPembelian.berat || } /> */}
                      </div>
                    </div>
                    <div className="w-full mb-4">
                      <label htmlFor="karat" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Karat
                        <span className="text-red-500 ml-3">( {idEditPembelian.karat} karat)</span>
                      </label>
                      <select
                        onChange={(e) => setKarat(e.target.value)}
                        id={'karat'}
                        name="karat"
                        className="select select-bordered w-full"
                      >
                        <option value="">Pilih Karat</option>
                        {karatOption.map((item, i) => (
                          <option key={i} value={item.karat}>
                            {item.karat}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-full mb-4">
                      <label
                        htmlFor="kondisi_barang"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Kondisi Barang
                        <span className="text-red-500 ml-3">( {idEditPembelian.kondisi_barang})</span>
                      </label>
                      <select
                        onChange={(e) => setKondisiBarang(e.target.value)}
                        id={'kondisi_barang'}
                        name="kondisi_barang"
                        className="select select-bordered w-full"
                      >
                        <option value="">Pilih Kondisi Barang</option>
                        {kondisiBarangOption.map((item, i) => (
                          <option key={i} value={item.kondisi}>
                            {item.kondisi}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="harga_beli"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Total Harga{' '}
                        <span className="text-red-500 font-semibold">
                          Rp{' '}
                          {numberWithCommas(
                            idEditPembelian?.harga_beli === undefined ? '0' : idEditPembelian?.harga_beli,
                          )}
                        </span>
                      </label>
                      <input
                        type="number"
                        id="harga_beli"
                        defaultValue={harga_beli || ''}
                        name="harga_beli"
                        onChange={(e) => setHargaBeli(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                      />
                      {/* </div>
                    <div className="flex items-center gap-2"> */}
                      <input
                        checked={is_change_harga}
                        onChange={handleCheckbox}
                        required
                        type="checkbox"
                        id="nominal_harga"
                        className="border-gray-300 rounded h-4 mt-1 w-4 cursor-pointer"
                      />
                      <label className="cursor-pointer" htmlFor="nominal_harga">
                        &nbsp;Apakah nominal harga ini dirubah?
                      </label>
                    </div>
                    <div className="flex items-center gap-2 mb-4">
                      {/* <input
                        checked={is_change_harga}
                        onChange={handleCheckbox}
                        required
                        type="checkbox"
                        id="nominal_harga"
                        className="border-gray-300 rounded h-4 mt-1 w-4 cursor-pointer"
                      /> */}
                      {/* <label
                        className="cursor-pointer"
                        htmlFor="nominal_harga"
                      >
                        Apakah nominal harga ini dirubah?
                      </label> */}
                    </div>
                    <div className={`${isSelected ? `hidden` : ``}`}>
                      {isOpenEditTrx && (
                        <ImageComponent
                          onClick={() => setOpenImage(true)}
                          className={'max-w-[80px]  hover:scale-110 cursor-pointer'}
                          width={'80px'}
                          height={'80px'}
                          style={{ objectFit: 'cover' }}
                          src={
                            idEditPembelian.gambar === null ? '' : `${imageBaseUrl}${idEditPembelian.gambar[0].asli}`
                          }
                        />
                      )}
                    </div>
                    <div className="mb-3 mt-3">
                      <div>
                        <div className={`mt-2 flex gap-4 p-2  ${selectImage.length === 0 ? 'hidden' : ''}`}>
                          {selectImage.map((image, index) => (
                            <div key={index}>
                              <button
                                onClick={() => setSelectImage(selectImage.filter((e) => e !== image))}
                                className="absolute bg-white/50 rounded-full p-1"
                              >
                                <IconX width={20} />
                              </button>
                              <img width={90} src={image} key={index} alt="" srcSet="" />
                            </div>
                          ))}
                        </div>
                        <input
                          type="file"
                          required
                          onChange={handleImageChange}
                          className="
                  file:bg-blue-500 file:rounded-md file:border-none  w-full file:p-2 file:text-white file:cursor-pointer
                    bg-green-500/20 rounded-md  p-2 font-semibold
                "
                          accept="image/png , image/jpeg, image/webp"
                        />
                      </div>
                    </div>

                    <div className="mt-4 flex gap-2 justify-end">
                      <button
                        type="button"
                        className="text-white  bg-slate-600 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 font-medium text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800"
                        onClick={closeModalEditTrx}
                      >
                        Tutup
                      </button>
                      <button
                        type="submit"
                        className="text-white  bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                        onClick={editTrxPembelian}
                      >
                        {submitPembelian ? 'Proses ...' : 'Simpan'}
                      </button>
                      
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
