import {
    IconCalendar,
    IconChevronLeft,
    IconChevronRight,
    IconReload,
    IconSearch,
  } from '@tabler/icons-react';
  import React, { useEffect, useState } from 'react';
  import { DateRangePicker } from 'react-date-range';
  import { useNavigate } from 'react-router-dom';
  import { toast } from 'react-toastify';
  import { getAuthorizationHeader, http, imageBaseUrl } from '../../config/http';
  import Layouts from '../../layouts/Layouts';
  import { DinamicTitle } from '../../utils/DinamicTitle';
  import DetailTransaksiPembelian from '../Transaksi/DetailTransaksiPembelian';
  import moment from 'moment';
  import Input from '../../components/Input';
  import EditTrxPembelian from '../Transaksi/EditTrxPembelian';
  import ConfirmationDeletePembelian from '../../components/Admin/ConfirmationDeletePembelian';
  import useKarat from '../features/useKarat';
  import LoadingIndicator from '../../components/LoadingIndicator';
import { PhotoView } from 'react-photo-view';
  
  const GaleriPembelian = () => {
    const navigate = useNavigate();
    const [submitPembelian, setSubmitPembelian] = useState(false);
    const [isSubmitEdit, setIsSubmitEdit] = useState(false);
  
    const [trxPembelian, setTrxPembelian] = useState([]);
    // console.log(trxPembelian)
    const [detailPembelian, setDetailPembelian] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pembelian_id, setIdTrxPembelian] = useState('');
    const [teknisi_id, setTeknisi] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isSubmitBarangOpname, setIsSubmitBarangOpname] = useState(false);
  
    const [show, setShow] = useState(false);
    const [submitChange, setSubmitChange] = useState(false);
    let [isOpen, setIsOpen] = useState(false);
  
    const [kategori, setKategori] = useState([]);
    // console.log('kategori::: ', kategori);
    const [selectCabang, setSelectCabang] = useState([]);
  
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [search, setSearch] = useState('');
  
    // FILTER STATE
    const [idCabang, setIdCabang] = useState(0);
    const [idKategori, setIdKategori] = useState(0);
    const [openCalender, setOpenCalender] = useState(false);
    const [kodeTransaksi, setKodeTransaksi] = useState('');
  
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const awal = moment(startDate).format('DD-MM-YYYY');
    const akhir = moment(endDate).format('DD-MM-YYYY');
  
    const [deleteId, setDeleteId] = useState('');
    const [openLoading, setOpenLoading] = useState(false);
    const [loadingDel, setLoadingDel] = useState(false);
    const [pembelianDelete, setPembelianDelete] = useState([]);
    const [isDelete, setIsDelete] = useState(false);
    let [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  
    const { karatOption, karatOptionId, setKaratOptionId } = useKarat();
  
    const [filterBerat, setFilterBerat] = useState('');
    const [berat, setBerat] = useState('');
  
    const filterBeratBarang = () => {
      setBerat(filterBerat);
    };
    
    const [selectedKategori, setSelectedKategori] = useState('');
    const [selectJenisKategori, setSelectJenisKategori] = useState('');
    
    const handleKategoriChange = (event) => {
        setSelectedKategori(event.target.value);
        setSelectJenisKategori('');
    };

    const handleJenisKategoriChange = (event) => {
      setSelectJenisKategori(event.target.value);
    };
    
    function closeModalDelete() {
      setIsOpenModalDelete(false);
    }
  
    function openModalDelete() {
      setIsOpenModalDelete(true);
    }
  
    function handleOnChangeTeknisi(e) {
      setTeknisi(e.target.value);
    }
  
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  
    async function getTrxPembelian() {
      setLoading(true);
      await http
        .get(
          `pembelian${search === '' ? `?page=${currentPage}` : `?faktur=${search}`}${(idCabang === 0 || idCabang === '') ? `` : `&cabang=${idCabang}`}${(selectedKategori === 0 || selectedKategori === '') ? `` : `&kategori=${selectedKategori}`}${(selectJenisKategori === 0 || selectJenisKategori === '') ? `` : `&jenis_kategori=${selectJenisKategori}`
          }${startDate === null && endDate === null ? `` : `&tgl_awal=${awal}&tgl_akhir=${akhir}`}${karatOptionId === '' ? '' : `&karat=${karatOptionId}`}${berat === '' ? '' : `&berat=${berat}`}`,
          {
            headers: { Authorization: getAuthorizationHeader() },
          },
        )
        .then((res) => {
          setLastPage(res.data.data.last_page);
          setCurrentPage(res.data.data.current_page);
          setItemsPerPage(res.data.data.per_page);
          setTrxPembelian(res.data.data.data);
          return res.data.data.data;
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response?.status === 401) {
            navigate('/login');
          }
        });
      setLoading(false);
    }
  
    useEffect(() => {
      getTrxPembelian();
    }, [
      isSubmit === true,
      isSubmitEdit == true,
      idCabang,
      search.length >= 12 ? search : '',
      currentPage,
      startDate,
      endDate,
      awal,
      akhir,
      // idKategori,
      selectedKategori,
      selectJenisKategori,
      karatOptionId,
      berat,
    ]);
  
    async function getIdPembelian(id) {
      setShow(true);
      setSubmitChange(true);
      await http
        .get(`/pembelian/${id}`, {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          // console.log('res::: ', res);
          setKodeTransaksi(res.data.data.kode_trx);
          setIdTrxPembelian(res.data.data.id);
          setDetailPembelian(res.data.data);
          setIsOpen(true);
          return res.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
      setSubmitChange(false);
      setShow(false);
    }
  
    async function addToOpname() {
      const data = { pembelian_id, teknisi_id };
      setIsSubmitBarangOpname(true);
      try {
        const response = await http.post(`/stok-opname`, data, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        setIsOpen(false);
        setIsSubmit(true);
        setIsSubmitBarangOpname(false);
        toast.success(response.data.message, { autoClose: 600, });
        return response.data;
      } catch (error) {
        if (error.response.status === 409) {
          toast.error(error.response.data.message, { autoClose: 600, });
        } else if (error.response.status === 422) {
          toast.warning('Silahkan masukkan teknisi', { autoClose: 600, });
        }
      }
      setIsSubmitBarangOpname(false);
    }
  
    function closeModal() {
      setIsOpen(false);
    }
  
    const selectionRange = {
      startDate: startDate,
      endDate: endDate,
      key: 'selection',
    };
  
    function handleSelect(date) {
      setStartDate(date.selection.startDate);
      setEndDate(date.selection.endDate);
    }
  
    DinamicTitle('Galeri Pembelian || Komaladewi');
  
    // GET CABANG FILTER
    useEffect(() => {
      async function getCabangSelect() {
        await http
          .get(`cabang?is_toko=1`, {
            headers: { Authorization: getAuthorizationHeader() },
          })
          .then((res) => {
            setSelectCabang(res.data.data);
          })
          .catch((err) => {
            if (err.response === 'undefined') {
              return null;
            } else if (err.response.status === 401) {
              navigate('/login');
            }
          });
      }
      getCabangSelect();
    }, []);
    // END GET CABANG FILTER
  
    // GET KATEGORI FILTER
    useEffect(() => {
      async function getKategori() {
        await http
          .get('/kategori', {
            headers: { Authorization: getAuthorizationHeader() },
          })
          .then((res) => {
            // console.log('res::: ', res);
            setKategori(res.data);
          })
          .catch((err) => {
            if (err.response === 'undefined') {
              return null;
            } else if (err.response.status === 401) {
              navigate('/login');
            }
          });
      }
      getKategori();
    }, []);
  
    // TRANFER ITEM
  
    const [itemsPerPage, setItemsPerPage] = useState('');
    const startIndex = (currentPage - 1) * itemsPerPage;
    const [loadingEditPembelian, setLoadingEditPembelian] = useState(false);
    let [isOpenEditTrx, setIsOpenEditTrx] = useState(false);
  
    function closeModalEditTrx() {
      setIsOpenEditTrx(false);
    }
  
    function openModalEditTrx() {
      setIsOpenEditTrx(true);
    }
    const [idEdit, setIdEdit] = useState('');
    // const [berat, setBerat] = useState('');
    const [karat, setKarat] = useState('');
    const [kondisi_barang, setKondisiBarang] = useState('');
    const [harga_beli, setHargaBeli] = useState('');
    const [jenis_kategori_id, setJenisKategoriIdBase] = useState('');
    const [is_change_harga, setIsChange] = useState(false);
    const [gambar, setGambar] = useState(null);
    // console.log({
    //   jenis_kategori_id, berat, karat, harga_beli, is_change_harga, gambar
    // })
    
    const [idEditPembelian, setIdEditPembelian] = useState([]);
    async function getIdEditPembelian(id) {
      setLoadingEditPembelian(true);
      await http
        .get(`/pembelian/${id}`, {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setJenisKategoriIdBase(res.data.data.jenis_kategori_id);
          setIdEditPembelian(res.data.data);
          openModalEditTrx();
          setIdEdit(res.data.data.id);
          setBerat(res.data.data.berat);
          setHargaBeli(res.data.data.harga_beli);
          setKarat(res.data.data.karat);
          setGambar(res.data.data.gambar[0].asli);
          return res.data;
        })
        .catch((err) => {
          if (err.response?.status == 422) {
            toast.warning('Periksa data yang diubah', { autoClose: 600, });
          }
          console.log(err.response);
        });
      setLoadingEditPembelian(false);
    }
    const [isSelected, setIsSelected] = useState(false);
    async function editTrxPembelian(e) {
      e.preventDefault();
      setSubmitPembelian(true);
      setIsSubmitEdit(true);
  
      try {
        const data = { berat, harga_beli, karat, gambar, jenis_kategori_id, is_change_harga, kondisi_barang };
        const response = await http.put(`pembelian/${idEdit}`, data, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        toast.success(response.data.message || 'Data berhasil diupdate', { autoClose: 600, });
        closeModalEditTrx();
        setSubmitPembelian(false);
        setIsSelected(false);
      } catch (error) {
        if (error.code === 'ECONNABORTED') {
          toast.warning('Waktu permintaan ke server habis', { autoClose: 600, });
        } else {
          console.log(error);
        }
      }
      setTimeout(() => {
        setSubmitPembelian(false);
      }, 1000);
      setTimeout(() => {
        setIsSubmitEdit(false);
      }, 1000);
    }
  
    async function getIdDelete(id) {
      setLoadingDel(true);
      await http
        .get(`pembelian/${id}`, {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          openModalDelete();
          setDeleteId(res.data.data.id);
          setPembelianDelete(res.data.data);
          setLoadingDel(false);
        })
        .catch((err) => {
          console.log(err);
        });
      setLoadingDel(false);
    }
  
    async function deletePembelian() {
      setIsDelete(true);
      await http
        .delete(`pembelian/${deleteId}`, {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          closeModalDelete();
          toast.success(res.data.message, { autoClose: 600, });
          getTrxPembelian();
        })
        .catch((err) => {
          console.log(err);
        });
      setIsDelete(false);
    }
    
    const tgl = (created_at) => {
      return String(created_at).split(' ')[0];
    }

    return (
      <Layouts
        dateFilter={`${awal === 'Invalid date' ? '' : ` : ${awal}`} ${awal == akhir ? `` : `${akhir === 'Invalid date' ? '' : ` s/d ${akhir}`}`}`}
        title={'Galeri Pembelian'}
        compFilter={
          <div className="flex items-center gap-0.5">
            <div className="dropdown dropdown-bottom">
              <div
                tabIndex={0}
                onClick={() => setOpenCalender(!openCalender)}
                className="bg-orange-500 text-white p-2  cursor-pointer"
              >
                <IconCalendar className="text-white" />
              </div>
              <div tabIndex={0} className={`dropdown-content -right-40 menu p-2 shadow bg-base-100`}>
                <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
              </div>
            </div>
            <div onClick={() => window.location.reload()} className="bg-gray-800 text-white p-2  cursor-pointer">
              <IconReload className="text-white" />
            </div>
            <div className="flex justify-end ">
              <Input
                className="input input-bordered rounded-none"
                type="text"
                onChange={(e) => setSearch(e.target.value)}
                autoComplete="off"
                placeholder="Masukkan Pembelian"
              />
            </div>
          </div>
        }
      >
        <ConfirmationDeletePembelian
          isDelete={isDelete}
          deletePembelian={deletePembelian}
          pembelianDelete={pembelianDelete}
          isOpenModalDelete={isOpenModalDelete}
          closeModalDelete={closeModalDelete}
        />
  
        {openLoading || loadingDel ? (
          <LoadingIndicator />
        ) : (
          ''
        )}
  
        {loadingEditPembelian ? (
          <LoadingIndicator />
        ) : (
          <EditTrxPembelian
            idEditPembelian={idEditPembelian}
            submitPembelian={submitPembelian}
            setJenisKategoriIdBase={setJenisKategoriIdBase}
            setIsChange={setIsChange}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            gambar={gambar}
            setGambar={setGambar}
            is_change_harga={is_change_harga}
            editTrxPembelian={editTrxPembelian}
            berat={berat}
            karat={karat}
            setKarat={setKarat}
            setKondisiBarang={setKondisiBarang}
            harga_beli={harga_beli}
            setHargaBeli={setHargaBeli}
            setBerat={setBerat}
            isOpenEditTrx={isOpenEditTrx}
            closeModalEditTrx={closeModalEditTrx}
          />
        )}
  
        <div className="">
          <div>
            <div className="flex items-center gap-3 w-full scrollbar overflow-x-auto bg-white">
              <div className="flex items-center gap-0.5 justify-between">
                {/* <div className="flex gap-0.5"> */}
                  <div>
                    <div className="relative group">
                      <select
                        name=""
                        id=""
                        onChange={(e) => setIdCabang(e.target.value)}
                        className=" p-2  shadow-md cursor-pointer"
                      >
                        <option value="">ETALASE</option>
                        {selectCabang.map((cb) => (
                          <option value={cb.id} key={cb.id}>
                            {cb.nama_cabang}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  
                  <div>
                    <div className="relative group">
                      <select
                        value={selectedKategori}
                        name=""
                        id=""
                        // onChange={(e) => setIdKategori(e.target.value)}
                        onChange={handleKategoriChange}
                        className="p-2  shadow-md  cursor-pointer "
                      >
                        <option value="">KATEGORI</option>
                        {kategori.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.nama_kategori}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  
                  {selectedKategori && (
                    <div>
                      <div className="relative group">
                        <select
                            onChange={handleJenisKategoriChange}
                            value={selectJenisKategori}
                            className="border border-t-0 p-2 mr-0.5 cursor-pointer"
                        >
                            <option value="">JENIS KATEGORI</option>
                            {kategori
                                .find((kt) => kt.id == selectedKategori)
                                ?.jenis_kategori.map((jk) => (
                                    <option key={jk.id} value={jk.id}>
                                        {jk.nama_jenis}
                                    </option>
                                ))}
                        </select>
                      </div>
                    </div>
                  )}

                  <div>
                    <div className="relative group">
                      <select
                        name=""
                        id=""
                        onChange={(e) => setKaratOptionId(e.target.value)}
                        className="p-2 shadow-md  cursor-pointer "
                      >
                        <option value="">KARAT</option>
                        {karatOption.map((kto) => (
                          <option key={kto} value={kto}>
                            {kto}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>                  
                  {/* <div className="max-w-lg justify-end flex w-52 gap-0.5 items-center">
                    <Input onChange={(e) => setFilterBerat(e.target.value)} placeholder="Cari Berat" />
                    <button onClick={filterBeratBarang} className="bg-teal-500 px-1.5 h-[2.30rem] text-white">
                      <IconSearch />
                    </button>
                    <button
                      onClick={() => {
                        function reloadData() {
                          getTrxPembelian()
                        }
                        reloadData();
                      }}
                      className="bg-gray-800 text-white px-1.5 h-[2.30rem]"
                    >
                      <IconReload />
                    </button>
                  </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
  
          {show ? (
            <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700/20 opacity-75 flex flex-col items-center justify-center">
              <div className="w-12 h-12  rounded-full animate-spin border-4 border-dashed border-green-500 border-t-transparent"></div>
            </div>
          ) : (
            <DetailTransaksiPembelian
              kodeTransaksi={kodeTransaksi}
              kategori={kategori}
              isSubmitBarangOpname={isSubmitBarangOpname}
              submitChange={submitChange}
              detailPembelian={detailPembelian}
              addToOpname={addToOpname}
              handleOnChangeTeknisi={handleOnChangeTeknisi}
              closeModal={closeModal}
              isOpen={isOpen}
            />
          )}
          <div>
            <div className="border-t">
              <div className="inline-block min-w-full overflow-hidden">
                {loading ? (
                  <tr className="flex">
                    <td className="w-5 h-5  rounded-full animate-spin border-4 border-dashed border-slate-800 border-t-transparent"></td>
                    <td className=" font-semibold px-2 py-1 rounded-br-md rounded-bl-md">Loading....</td>
                  </tr>
                ) : (
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4 mb-20">
                    {trxPembelian.map((trx, index) => {
                        return (
                            <div key={index} className="relative group">
                                <PhotoView
                                  src={trx?.gambar === null ? '' : `${imageBaseUrl}${trx?.gambar[0]?.asli}`}
                                >
                                    <img
                                        src={trx?.gambar === null ? '' : `${imageBaseUrl}${trx?.gambar[0]?.asli}`}
                                        alt=""
                                        className="w-full h-auto rounded cursor-pointer"
                                    />
                                </PhotoView>

                                <p className="absolute top-0 left-0 m-2 rounded text-gray bg-yellow-500 p-1">
                                    {trx?.user?.cabang?.nama_cabang}
                                </p>
                                <p className="absolute top-0 right-0 m-2 rounded text-gray bg-yellow-500 p-1">
                                    {trx?.kondisi_barang}
                                </p>

                                <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 pt-2 px-2 flex flex-col justify-center gap-0">
                                    <p className="text-white text-base -mb-1">{trx?.jenis_kategori?.nama_jenis} | {tgl(trx?.created_at)}</p>
                                    
                                    {/* <p className="text-white text-base font-bold -mb-2">{trx?.kode_trx}</p> */}
                                    {/* <p className="text-white text-base mb-1">{trx?.berat} gr | {trx?.karat} krt |  */}
                                    <p className="text-white text-base mb-1">{trx?.berat} gr | {trx?.karat} krt |
                                    Rata-rata : Rp{' '}
                                      {numberWithCommas(
                                        trx?.total_harga == undefined ? '0' : Math.round(trx?.total_harga / trx?.berat),
                                      )}{' '}
                                    </p>
                                    {/* <p className="text-white text-base">
                                     
                                    </p> */}
                                </div>
                            </div>
                        );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white bottom-0 flex justify-between left-0 py-4 w-full absolute">
          <div className="flex justify-end items-center w-full mx-5">
            <div className="items-center flex justify-center space-y-2 text-xs sm:space-y-0 sm:space-x-3 sm:flex">
              <span className="block">
                Page {currentPage} of {lastPage}{' '}
              </span>
              <div className="space-x-1">
                <button
                  onClick={() => setCurrentPage(currentPage - 1)}
                  title="previous"
                  type="button"
                  className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow"
                >
                  <IconChevronLeft />
                </button>
                <button
                  onClick={() => setCurrentPage(currentPage + 1)}
                  title="next"
                  type="button"
                  className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow"
                >
                  <IconChevronRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Layouts>
    );
  };
  
  export default GaleriPembelian;
  