import { IconCalendar, IconChevronLeft, IconChevronRight, IconRefresh, IconSearch } from '@tabler/icons-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { useNavigate } from 'react-router-dom';
import Input from '../../components/Input';
import { getAuthorizationHeader, http, imageBaseUrl } from '../../config/http';
import Layouts from '../../layouts/Layouts';
import { DinamicTitle } from '../../utils/DinamicTitle';
import { IconReload } from '@tabler/icons-react';
import useKarat from '../features/useKarat';
import DetailBarangPenjualan from '../Transaksi/DetailBarangPenjualan';
import { PhotoView } from 'react-photo-view';

const GaleriPenjualan = () => {
    const navigate = useNavigate();
    const { karatOption, karatOptionId, setKaratOptionId } = useKarat();
    const [trxPenjualan, setTrxPenjualan] = useState([]);
    // console.log(trxPenjualan)
    const [openLoading, setOpenLoading] = useState(false);
    const [detailPenjualan, setDetailPenjualan] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectCabang, setSelectCabang] = useState([]);
    const [idCabang, setIdCabang] = useState(0);
    const [idKategori, setIdKategori] = useState(0);
    const [kategori, setKategori] = useState([]);

    const [openCalender, setOpenCalender] = useState(false);
    const [filterDate, setFilterDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const awal = moment(startDate).format('DD-MM-YYYY');
    const akhir = moment(endDate).format('DD-MM-YYYY');

    // const [totalHargaPenjualan, setTotalHargaPenjualan] = useState(0);
    // const [totalHargaFilter, setTotalHargaFilter] = useState(0);

    const [filterBerat, setFilterBerat] = useState('');
    const [berat, setBerat] = useState('');

    const filterBeratBarang = () => {
        setBerat(filterBerat);
    };

    const [selectedKategori, setSelectedKategori] = useState('');
    const [selectJenisKategori, setSelectJenisKategori] = useState('');
    
    const handleKategoriChange = (event) => {
        setSelectedKategori(event.target.value);
        setSelectJenisKategori('');
    };

    const handleJenisKategoriChange = (event) => {
      setSelectJenisKategori(event.target.value);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [search, setSearch] = useState('');

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const [itemsPerPage, setItemsPerPage] = useState('');
    const startIndex = (currentPage - 1) * itemsPerPage;

    useEffect(() => {
        async function getTrxPenjualan() {
            setLoading(true);
            await http
                .get(
                    `penjualan${search === '' ? `?page=${currentPage}` : `?faktur=${search}`}${(idCabang === 0 || idCabang === '') ? `` : `&cabang=${idCabang}`}
          ${(selectedKategori === 0 || selectedKategori === '') ? `` : `&kategori=${selectedKategori}`}${(selectJenisKategori === 0 || selectJenisKategori === '') ? `` : `&jenis_kategori=${selectJenisKategori}`}${startDate === null && endDate === null ? `` : `&tgl_awal=${awal}&tgl_akhir=${akhir}`
                    }${karatOptionId === '' ? '' : `&karat=${karatOptionId}`}${berat === '' ? '' : `&berat=${berat}`}`,
                    {
                        headers: { Authorization: getAuthorizationHeader() },
                    },
                )
                .then((res) => {
                    setTrxPenjualan(res.data.data.data);
                    setCurrentPage(res.data.data.current_page);
                    setItemsPerPage(res.data.data.per_page);
                    setLastPage(res.data.data.last_page);
                    return res.data.data;
                })
                .catch((err) => {
                    if (err.response === 'undefined') {
                        return null;
                    } else if (err.response.status === 401) {
                        navigate('/login');
                    }
                });
            setLoading(false);
        }
        getTrxPenjualan();
    }, [
        idCabang,
        // idKategori,
        search.length >= 12 ? search : '',
        awal,
        akhir,
        startDate,
        endDate,
        currentPage,
        navigate,
        karatOptionId,
        berat,
        selectedKategori,
        selectJenisKategori,
    ]);

    let [isOpen, setIsOpen] = useState(false);

    function closeModal() {
        setIsOpen(false);
    }
    async function showDetailPenjualan(id) {
        setOpenLoading(true);
        await http
            .get(`penjualan/${id}`, {
                headers: { Authorization: getAuthorizationHeader() },
            })
            .then((res) => {
                setDetailPenjualan(res.data.data);
                setIsOpen(true);
            });
        setOpenLoading(false);
    }

    useEffect(() => {
        async function getCabangSelect() {
            await http
                .get(`cabang?is_toko=1`, {
                    headers: { Authorization: getAuthorizationHeader() },
                })
                .then((res) => {
                    setSelectCabang(res.data.data);
                })
                .catch((err) => {
                    if (err.response === 'undefined') {
                        return null;
                    } else if (err.response.status === 401) {
                        navigate('/login');
                    }
                });
        }
        getCabangSelect();
    }, [navigate]);

    useEffect(() => {
        async function getKategori() {
            await http
                .get('/kategori', {
                    headers: { Authorization: getAuthorizationHeader() },
                })
                .then((res) => {
                    setKategori(res.data);
                })
                .catch((err) => {
                    if (err.response === 'undefined') {
                        return null;
                    } else if (err.response.status === 401) {
                        navigate('/login');
                    }
                });
        }
        getKategori();
    }, []);

    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
    };

    function handleSelect(date) {
        // console.log('date::: ', date);
        setStartDate(date.selection.startDate);
        setEndDate(date.selection.endDate);
    }

    function checkBerat(number) {
        const beratConfirm = parseFloat(number);
        return beratConfirm % 1 === 0
            ? beratConfirm.toString()
            : beratConfirm
                .toFixed(2)
                .replace(/\.?0+$/, '')
                .replace('.', ',');
    }

    DinamicTitle('Galeri Penjualan');

    return (
        <Layouts
            title={'Galeri Penjualan'}
            compFilter={
                <div className="flex items-center gap-1">
                    <div className="dropdown dropdown-bottom">
                        <div
                            tabIndex={0}
                            onClick={() => setOpenCalender(!openCalender)}
                            className="bg-orange-500 text-white p-2  cursor-pointer"
                        >
                            <IconCalendar className="text-white" />
                        </div>
                        <div tabIndex={0} className={`dropdown-content -right-40 menu p-2 shadow bg-base-100`}>
                            <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
                        </div>
                    </div>
                    <div onClick={() => window.location.reload()} className="bg-gray-800 text-white p-2 cursor-pointer">
                        <IconReload className="text-white" />
                    </div>
                    <div className="flex justify-end ">
                        <Input
                            className="input input-bordered rounded-none"
                            type="text"
                            onChange={(e) => setSearch(e.target.value)}
                            autoComplete="off"
                            placeholder="Cari Penjualan"
                        />
                    </div>
                </div>
            }
        >
            <div>
                <div className="flex items-center gap-3 w-full scrollbar overflow-x-auto bg-white">
                    <div className="flex items-center gap-0.5 justify-between">
                        <div>
                            <div className="relative group">
                                <select
                                    name=""
                                    id=""
                                    onChange={(e) => setIdCabang(e.target.value)}
                                    className=" p-2 cursor-pointer shadow-md"
                                >
                                    <option value=""> ETALASE</option>
                                    {selectCabang.map((cb) => (
                                        <option value={cb.id} key={cb.id}>
                                            {cb.nama_cabang}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div>
                            <div className="relative group">
                                <select
                                    name=""
                                    id=""
                                    value={selectedKategori}
                                    onChange={handleKategoriChange}
                                    // onChange={(e) => setIdKategori(e.target.value)}
                                    className=" p-2 cursor-pointer shadow-md"
                                >
                                    <option value="">KATEGORI</option>
                                    {kategori.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.nama_kategori}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        
                        {selectedKategori && (
                            <div>
                            <div className="relative group">
                                <select
                                    onChange={handleJenisKategoriChange}
                                    value={selectJenisKategori}
                                    className="border border-t-0 p-2 mr-0.5 cursor-pointer"
                                >
                                    <option value="">JENIS KATEGORI</option>
                                    {kategori
                                        .find((kt) => kt.id == selectedKategori)
                                        ?.jenis_kategori.map((jk) => (
                                            <option key={jk.id} value={jk.id}>
                                                {jk.nama_jenis}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            </div>
                        )}
                        
                        <div>
                            <div className="relative group">
                                <select
                                    name=""
                                    id=""
                                    onChange={(e) => setKaratOptionId(e.target.value)}
                                    className="p-2  shadow-md  cursor-pointer "
                                >
                                    <option value="">KARAT</option>
                                    {karatOption.map((kto) => (
                                        <option key={kto} value={kto}>
                                            {kto}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/* <div className="max-w-lg flex w-52 gap-0.5 items-center">
                            <Input onChange={(e) => setFilterBerat(e.target.value)} placeholder="Cari Berat" />
                            <button onClick={filterBeratBarang} className="bg-teal-500 px-1.5 h-[2.30rem] text-white">
                                <IconSearch />
                            </button>
                            <button
                                onClick={() => window.location.reload()}
                                className="bg-gray-800 text-white px-1.5 h-[2.30rem]"
                            >
                                <IconReload />
                            </button>
                        </div> */}
                    </div>
                </div>
                <div className='border-t'>
                    <DetailBarangPenjualan
                        label={'Penjualan'}
                        checkBerat={checkBerat}
                        closeModal={closeModal}
                        detailPenjualan={detailPenjualan}
                        isOpen={isOpen}
                    />
                    {openLoading ? (
                        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700/20 opacity-75 flex flex-col items-center justify-center">
                            <div className="w-12 h-12  rounded-full animate-spin border-4 border-dashed border-green-500 border-t-transparent"></div>
                        </div>
                    ) : (
                        ''
                    )}
                    {loading ? (
                        <>
                            <tr className="flex  items-center justify-center">
                                <td className="w-5 h-5  rounded-full animate-spin border-4 border-dashed border-slate-800 border-t-transparent"></td>
                                <td className=" font-semibold px-2 py-1 rounded-br-md rounded-bl-md">Memuat data ....</td>
                            </tr>
                        </>
                    ) : (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4 mb-20">
                            {trxPenjualan.map((trx, index) => {
                                return (
                                    <div key={index} className="relative group">
                                        <PhotoView
                                            src={trx?.item?.gambar1 === null ? '' : `${imageBaseUrl}${trx?.item?.gambar1[0]?.asli}`}
                                        >
                                            <img
                                                src={trx?.item?.gambar1 === null ? '' : `${imageBaseUrl}${trx?.item?.gambar1[0]?.asli}`}
                                                alt=""
                                                className="w-full h-auto rounded cursor-pointer"
                                            />
                                        </PhotoView>
                                        <p className="absolute top-0 left-0 m-2 rounded text-gray bg-yellow-500 p-1">
                                            {trx?.user?.cabang?.nama_cabang}
                                        </p>
                                        <p className="absolute top-0 right-0 m-2 rounded text-gray bg-yellow-500 p-1">
                                            {trx?.item?.karat} krt
                                        </p>
                                        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 pt-2 px-2 flex flex-col justify-center gap-0">
                                            {/* <p className="text-white text-base font-bold -mb-2">{trx?.kode_trx}</p> */}
                                            <p className="text-white text-base -mb-1">{trx?.kode_trx} | {trx?.item?.jenis_kategori?.nama_jenis}</p>
                                            {/* <p className="text-white text-base mb-1">{trx?.item?.berat} gr | {trx?.item?.karat} krt |  */}
                                            <p className="text-white text-base mb-1">{trx?.item?.berat} gr | 
                                            Rata-rata : Rp{' '}
                                            {numberWithCommas(
                                                trx.total_harga == undefined ? '0' : Math.round(trx.total_harga / trx.item?.berat),
                                            )}{' '}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}

                </div>
            </div>
            <div className="bg-white bottom-0 flex justify-between left-0 py-4 w-full absolute">
                <div className="flex justify-end w-full mx-5">
                    <div className="items-center flex justify-center space-y-2 text-xs sm:space-y-0 sm:space-x-3 sm:flex">
                        <span className="block">
                            Page {currentPage} of {lastPage}{' '}
                        </span>
                        <div className="space-x-1">
                            <button
                                onClick={() => setCurrentPage(currentPage - 1)}
                                title="previous"
                                type="button"
                                className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow"
                            >
                                <IconChevronLeft />
                            </button>
                            <button
                                onClick={() => setCurrentPage(currentPage + 1)}
                                title="next"
                                type="button"
                                className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow"
                            >
                                <IconChevronRight />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Layouts>
    );
};

export default GaleriPenjualan;
