import { IconCloudUpload, IconReload, IconX } from '@tabler/icons-react';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAuthorizationHeader, http } from '../../config/http';
import Layouts from '../../layouts/Layouts';
import { CurrencyInput } from '../../utils/CurrencyInput';
import { DinamicTitle } from '../../utils/DinamicTitle';

function TambahBarang() {
  DinamicTitle('Tambah Barang || Komaladewi');
  const navigate = useNavigate();
  const [kategori, setKategori] = useState([]);
  const [jenis_kategori_id, setJeniKategoriId] = useState('');
  const [berat, setBerat] = useState('');
  const [karat, setKarat] = useState('');
  // const [cabang_id, setCabangId] = useState('');
  const [cabang, setCabang] = useState([]);
  const [jenisKategori, setJenisKategori] = useState([]);
  const [harga_modal, setHargaModal] = useState(0);
  const [gambar1, setGambar1] = useState('');
  const [nomor_rak, setNomerRak] = useState('');
  // console.log('nomor_rak::: ', nomor_rak);
  const [selectImage, setSelectImage] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getCabang() {
      await http
        .get('/cabang', {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          setCabang(res.data.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          }
        });
    }
    getCabang();
  }, []);

  useEffect(() => {
    async function getKategori() {
      await http
        .get('/kategori', {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          // console.log('res::: ', res);
          setKategori(res.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
    }
    getKategori();
  }, [navigate]);

  function resetForm() {
    setJeniKategoriId('');
    setBerat('');
    // setCabangId('');
    setKarat('');
    setHargaModal(0);
    setGambar1('');
    setNomerRak('');
    setSelectImage([]);
    // console.log('Hallo')
  }

  // useEffect(() => {
  //   const regex = /^\d*\.?\d{0,3}$/;
  //   if (berat && !regex.test(berat)) {
  //     setBerat({
  //       berat: berat.slice(0, -1),
  //     });
  //   }
  // }, [berat]);
  useEffect(() => {
    const regex = /^\d*\.?\d{0,3}$/;
    if (berat && !regex.test(berat)) {
      if (berat.length > 3) {
        setBerat(berat.slice(0, berat.length - 1));
      } else {
        setBerat(berat);
      }
    }
  }, [berat]);

  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    if (selectImage.length < 1) {
      const reader1 = new FileReader();
      reader1.addEventListener('load', function () {
        setGambar1(reader1.result.split(',')[1]);
      });
      reader1.readAsDataURL(selectedFiles[0]);
    }
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setSelectImage(imagesArray);
    // FOR BUG IN CHROME
    event.target.value = '';
  };

  function deleteHandler(image) {
    setSelectImage(selectImage.filter((e) => e !== image));
    URL.revokeObjectURL(image);
    setGambar1('');
  }
  async function handleSubmitItem(e) {
    e.preventDefault();
    if (harga_modal !== 0) {
      try {
        setLoading(true);
        const response = await http.post(
          `/item`,
          {
            jenis_kategori_id,
            berat,
            karat,
            nomor_rak,
            harga_modal,
            // cabang_id,
            gambar1,
          },
          {
            headers: { Authorization: getAuthorizationHeader() },
          },
        );
        toast.success('Data berhasil disimpan', { autoClose: 600, });
        setLoading(false);
        resetForm();
        return response.data;
      } catch (error) {
        toast.warning('Silahkan ganti gambar', { autoClose: 600, });
        if (error.response?.status === 401) {
          navigate('/login');
        } else if (error.response?.status === 422) {
          toast.warning('Lengkapi input data', { autoClose: 600, });
        } else if (error.code === 'ERR_NETWORK') {
          toast.warning('Periksa jaringan internet', { autoClose: 600, });
        }
      }
    } else {
      toast.error('Input harga modal tidak boleh kosong', { autoClose: 600, });
    }
    setLoading(false);
  }

  async function selectJenisKategori(id) {
    await http
      .get(`/jenis/kategori/${id}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        setJenisKategori(res.data.data);
        // console.log('data ::', res)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const karatOption = [
    { karat: 16 },
    { karat: 17 },
    { karat: 18 },
    { karat: 19 },
    { karat: 20 },
    { karat: 21 },
    { karat: 22 },
    { karat: 23 },
    { karat: 24 },
  ];

  const bakiOption = [
    { baki: 1 },
    { baki: 2 },
    { baki: 3 },
    { baki: 4 },
    { baki: 5 },
    { baki: 6 },
    { baki: 7 },
    { baki: 8 },
    { baki: 9 },
    { baki: 10 },
    { baki: 11 },
    { baki: 12 },
    { baki: 13 },
    { baki: 14 },
    { baki: 15 },
    { baki: 16 },
    { baki: 17 },
    { baki: 18 },
    { baki: 19 },
    { baki: 20 },
  ];
  return (
    <Layouts
      // title={'Tambah Barang'}
      compFilter={
        <button onClick={() => window.location.reload()} className="bg-slate-400 p-1.5 text-white">
          <IconReload />
        </button>
      }
    >
      <div className="bg-white h-full shadow p-3">
        <h2 className="text-2xl font-semibold mb-1 leading-6 text-gray-900">Tambah Barang</h2>
        <div className="mt-4">
          <form>
            <div className="mb-2">
              <div className="flex justify-between items-center">
                <label htmlFor="kategori" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                  Kategori
                </label>
              </div>
              <select onChange={(e) => selectJenisKategori(e.target.value)} className="select select-bordered rounded-none w-full">
                <option value="">Pilih Kategori</option>
                {kategori.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.nama_kategori}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-2">
              <label htmlFor="kategori" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                Jenis Kategori
              </label>
              <select
                value={jenis_kategori_id}
                onChange={(e) => setJeniKategoriId(e.target.value)}
                className="select select-bordered w-full rounded-none"
              >
                <option value=""> Jenis Kategori</option>
                {jenisKategori.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.nama_jenis}
                  </option>
                ))}
              </select>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div className="mb-2">
                <label htmlFor="berat" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                  Berat
                </label>
                <input
                  type="number"
                  id="berat"
                  autoComplete="off"
                  value={berat}
                  onChange={(e) => setBerat(e.target.value)}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="karat" className="block text-sm font-medium text-gray-900 dark:text-white">
                  Karat
                </label>
                <select
                  value={karat}
                  id={'karat'}
                  onChange={(e) => setKarat(e.target.value)}
                  className="rounded-none w-full border mt-1 h-[2.65rem] border-slate-300"

                >
                  <option value="">Karat</option>
                  {karatOption.map((item, i) => (
                    <option key={i} value={item.karat}>
                      {item.karat}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mb-6">
              <label htmlFor="nomor_rak" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                Nomor BAKI
              </label>
              <select
                  value={nomor_rak}
                  id={'nomor_rak'}
                  onChange={(e) => setNomerRak(e.target.value)}
                  className="rounded-none w-full border mt-1 h-[2.65rem] border-slate-300"

                >
                  <option value="">BAKI</option>
                  {bakiOption.map((item, i) => (
                    <option key={i} value={item.baki}>
                      {item.baki}
                    </option>
                  ))}
              </select>
              {/* <input
                type="number"
                id="nomor_rak"
                value={nomor_rak}
                autoComplete={'off'}
                onChange={(e) => setNomerRak(e.target.value)}
                name="nomor_rak"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              /> */}
            </div>
            <div className="mb-6">
              <label htmlFor="harga_modal" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                Harga Modal / Gram
              </label>
              <CurrencyInput
                name="harga_modal"
                value={harga_modal}
                autoComplete="off"
                onChange={(value) => setHargaModal(() => value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="mb-6">
              <div>
                <div className={`mt-2 flex gap-4 p-2  ${selectImage.length === 0 ? 'hidden' : ''}`}>
                  {selectImage.map((image, index) => (
                    <div key={index}>
                      <button onClick={() => deleteHandler(image)} className="absolute bg-white/50 rounded-full p-1">
                        <IconX width={20} />
                      </button>
                      <img width={90} src={image} key={index} alt="" srcSet="" />
                    </div>
                  ))}
                </div>
                <div>
                  {selectImage.length > 0 &&
                    (selectImage.length > 1 ? (
                      <div>
                        <p className="text-sm mx-3 text-red-500">
                          Sementara Kamu Hanya bisa Upload 1 Gambar <br />
                          <span className="text-sm">
                            Silahkan Hapus <b> {selectImage.length - 1} </b> dari {selectImage.length} Gambar diatas
                          </span>
                        </p>
                      </div>
                    ) : (
                      <div className="text-sm text-green-600 mb-1 mx-3">
                        UPLOAD {selectImage.length} IMAGE
                        {selectImage.length === 1 ? '' : 'S'}
                      </div>
                    ))}
                </div>
                <input
                  type="file"
                  required
                  onChange={handleImageChange}
                  className="
                  file:bg-blue-500 file:border-none  w-full file:p-2 file:text-white file:cursor-pointer
                    bg-green-500/20  p-2 font-semibold
                "
                  accept="image/png , image/jpeg, image/webp"
                />
              </div>
            </div>
            {loading ? (
              <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700/20 opacity-75 flex flex-col items-center justify-center">
                <div className="w-12 h-12  rounded-full animate-spin border-4 border-dashed border-green-500 border-t-transparent"></div>
              </div>
            ) : (
              ''
            )}
            <div className="flex justify-end gap-2">
              <button
                onClick={handleSubmitItem}
                className="text-white  bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium  text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              >
                {loading ? 'Proses..' : 'Simpan'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layouts>
  );
}

export default TambahBarang;
