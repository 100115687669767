import { useEffect, useState } from 'react';
import Layouts from '../../layouts/Layouts';
import { useNavigate } from 'react-router-dom';
import { getAuthorizationHeader, http } from '../../config/http';
import { toast } from 'react-toastify';
import { DinamicTitle } from '../../utils/DinamicTitle';
import DetailTransaksiTertunda from './DetailTransaksiTertunda';
import LayoutsKasir from '../../layouts/LayoutsKasir';

export default function TransaksiTertunda() {
  DinamicTitle('Transaksi Penggarus || Komaladewi');

  const [itemCabang, setItemCabang] = useState([]);
  const [loading, setLoading] = useState(false);
  const [kodeItem, setKodeItem] = useState('');

  const navigate = useNavigate();
  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key?.toLocaleLowerCase() === 'i' && e.ctrlKey) {
        document.getElementById('MySearch').focus();
      }
    });
  }, []);
  async function getItemCabang() {
    setLoading(true);
    await http
      .get(`/item/search/${kodeItem}`, {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then((res) => {
        if (res.data.data === null) {
          toast.warning('Data penjualan kosong', { autoClose: 600, });
        } else {
          setItemCabang(res.data.data);
        }
        return res.data.data;
      })
      .catch((err) => {
        if (err.response === undefined) {
          return null;
        } else if (err.response.status === 404) {
          if(kodeItem >= 8){
            toast.error('Data tidak ditemukan', { autoClose: 600, });
          }
        } else if (err.response.status === 401) {
          navigate('/login');
        }
      });
    setLoading(false);
  }

  useEffect(() => {
    if (kodeItem === '') {
      setItemCabang([]);
    } else if (kodeItem.length >= 8) {
      getItemCabang();
    }
  }, [kodeItem, navigate]);

  return (
    <LayoutsKasir>
      <div className="flex md:px-20 h-full mt-5 flex-row relative">
        <div className="flex items-center justify-between">
          <div className="absolute left-2 md:left-24 md:top-3 px-2 py-2 rounded-full bg-green-500 text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
          <div className="absolute md:right-20 right-0 px-2 mx-4  bg-gray-400/20 rounded-lg ">
            <p className="font-semibold text-slate-500 mt-2">CTRL + I</p>
          </div>
        </div>
        <input
          type="text"
          id="MySearch"
          onChange={(e) => setKodeItem(e.target.value)}
          className="bg-white rounded-lg shadow text-lg full w-full h-16 py-4 pl-16 transition-shadow focus:shadow-2xl focus:outline-dotted"
          placeholder="PENGGARUS ..."
          x-model="keyword"
        />
      </div>
      <div className="w-full">
        <DetailTransaksiTertunda isTertunda={true} itemCabang={itemCabang} detailPenjualan={itemCabang} loading={loading} kodeItem={kodeItem} />
      </div>
    </LayoutsKasir>
  );
}
