import { useEffect, useState } from 'react';

const usePage = () => {
  const [pageOptionId, setPageOptionId] = useState('');
  const [pageOption, setPageOption] = useState([]);

  useEffect(() => {
    const options = [];
    for (let i = 1; i <= 10; i++) {
      options.push(i);
    }
    setPageOption(options);
  }, []);
  return {
    pageOption,
    setPageOptionId,
    pageOptionId,
  };
};

export default usePage;
