import React, { useState } from 'react';
import {
  Icon3dCubeSphere,
  IconApps,
  IconBrandCashapp,
  IconBrandFirefox,
  IconChevronDown,
  IconClipboardText,
  IconCurrencyDollar,
  IconDeviceDesktopAnalytics,
  IconHome2,
  IconListCheck,
  IconReportMoney,
  IconShoppingCartPlus,
  IconTransferOut,
  IconUser,
} from '@tabler/icons-react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import AddJenisBarang from '../components/Admin/AddJenisBarang';
import { IconFolder } from '@tabler/icons-react';

const Navbar = () => {
  const [openMobile, setMobileOpen] = useState(false);
  const activeNav = 'border-b-2 lg:flex items-center md:py-3 border-green-600 text-green-600 ';
  const normalLink =
    'block py-1 md:py-3 pl-1 align-middle lg:flex items-center  no-underline hover:text-green-500 hover:border-green-600 hover:border-b-2 ';

  const navigate = useNavigate();
  function logout() {
    localStorage.removeItem('role');
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    navigate('/login');
  }
  const check = localStorage.getItem('role');
  const user = localStorage.getItem('name');

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  return (
    <nav className="bg-white fixed w-screen z-10 p-2 lg:p-0  shadow">
      <AddJenisBarang isOpen={isOpen} closeModal={closeModal} />
      <div className="w-full container mx-auto flex flex-wrap items-center mt-0 pt-3 pb-3 md:pb-0">
        <div className="w-1/2 pl-0 md:pl-0 -ml-10 ">
          <div className="text-gray-900 flex items-center text-base xl:text-xl no-underline hover:no-underline font-bold">
            <IconBrandFirefox className="text-green-600 ml-12 mr-4" />
            Komaladewi
          </div>
        </div>
        <div className="w-1/2 md:pr-10 pr-0">
          <div className="flex relative  float-right">
            <div className="relative text-sm ">
              <div className="dropdown dropdown-end">
                <label tabIndex={0} className="cursor-pointer">
                  <div className="flex items-center gap-4 break-words">
                    <div className="font-semibold text-base hidden sm:block">{user}</div>
                    <IconChevronDown />
                  </div>
                </label>
                <div tabIndex={0} className="dropdown-content menu p-2  shadow bg-base-100 rounded-box w-52">
                  {/* <Link className="text-start py-3 px-2 hover:bg-slate-400/30 rounded-md" to="#">
                    Accounts
                  </Link>

                  <Link className="text-start py-3 px-2 hover:bg-slate-400/30 rounded-md" to="#">
                    Settings
                  </Link> */}
                  <button onClick={logout} className="text-start py-3 px-2 hover:bg-slate-400/30 rounded-md">
                    Log out
                  </button>
                </div>
              </div>
            </div>
            <div className="block lg:hidden md:pr-4">
              <button
                onClick={() => setMobileOpen(!openMobile)}
                className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-900 hover:border-teal-500 appearance-none focus:outline-none"
              >
                <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div
          className={`${
            openMobile ? '' : 'hidden'
          } animated fadeIn faster w-full flex flex-grow lg:flex lg:items-center lg:w-auto  mt-2 lg:mt-0 bg-white z-20' `}
        >
          <div className="list-reset lg:flex flex-1 items-center px-4 md:px-12">
            <div className="mr-6 my-2 md:my-0">
              <NavLink to="/" className={({ isActive }) => (isActive ? activeNav : normalLink)}>
                <div className="flex items-center ">
                  <IconFolder className="mr-3 " />
                  <span className="md:pb-0 text-sm whitespace-nowrap">Dashboard</span>
                </div>
                <hr className="md:hidden mt-3" />
              </NavLink>
            </div>
            <div className={`mr-6 my-2 md:my-0 ${check === 'admin' ? 'hidden' : check === 'operator' ? 'hidden' : ''}`}>
              <div className="mr-6 my-2 md:my-0">
                <NavLink to="/penjualan" className={({ isActive }) => (isActive ? activeNav : normalLink)}>
                  <div className="flex items-center">
                    <IconFolder className="mr-3 " />
                    <span className=" md:pb-0 text-sm whitespace-nowrap">Kasir Penjualan</span>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className={`mr-6 my-2 md:my-0 ${check === 'admin' ? 'hidden' : check === 'operator' ? 'hidden' : ''}`}>
              <div className="mr-6 my-2 md:my-0">
                <NavLink to="/transaksi-tertunda" className={({ isActive }) => (isActive ? activeNav : normalLink)}>
                  <div className="flex items-center">
                    <IconFolder className="mr-3 " />
                    <span className=" md:pb-0 text-sm whitespace-nowrap">Kasir Penggarus</span>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className={`mr-6 my-2 md:my-0 ${check === 'admin' ? 'hidden' : check === 'operator' ? 'hidden' : ''}`}>
              <div className="mr-6 my-2 md:my-0">
                <NavLink to="/pembelian" className={({ isActive }) => (isActive ? activeNav : normalLink)}>
                  <div className="flex items-center">
                    <IconFolder className="mr-3 " />
                    <span className=" md:pb-0 text-sm whitespace-nowrap">Kasir Pembelian</span>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className={`mr-6 my-2 md:my-0 ${check === 'admin' ? 'hidden' : check === 'operator' ? 'hidden' : ''}`}>
              <div className="mr-6 my-2 md:my-0">
                <NavLink to="/pengembalian" className={({ isActive }) => (isActive ? activeNav : normalLink)}>
                  <div className="flex items-center">
                    <IconFolder className="mr-3 " />
                    <span className=" md:pb-0 text-sm whitespace-nowrap">Pengembalian</span>
                  </div>
                </NavLink>
              </div>
            </div>
            {/* <div className={`mr-6 my-2 md:my-0 ${check === 'admin' ? 'hidden' : check === 'operator' ? 'hidden' : ''}`}>
              <div className="mr-6 my-2 md:my-0">
                <NavLink to="/pengeluaran" className={({ isActive }) => (isActive ? activeNav : normalLink)}>
                  <div className="flex items-center">
                    <IconFolder className="mr-3 " />
                    <span className=" md:pb-0 text-sm whitespace-nowrap">Transaksi Luar</span>
                  </div>
                </NavLink>
              </div>
            </div> */}
            <div className={`mr-6 my-2 md:my-0 ${check === 'admin' ? 'hidden' : check === 'operator' ? 'hidden' : ''}`}>
              <div className="mr-6 my-2 md:my-0">
                <NavLink to="/laporan-cashflow" className={({ isActive }) => (isActive ? activeNav : normalLink)}>
                  <div className="flex items-center">
                    <IconFolder className="mr-3 " />
                    <span className=" md:pb-0 text-sm whitespace-nowrap">Laporan</span>
                  </div>
                </NavLink>
              </div>
            </div>
            {/* <div className={`mr-6 my-2 md:my-0 ${check === 'admin'  ? 'hidden' : check === 'operator' ? 'hidden':''}`}>
                            <div className='mr-6 my-2 md:my-0'>
                                <NavLink to='/laporan-cashflow' className={({ isActive }) => (isActive ? activeNav : normalLink)}>
                                    <div className='flex items-center'>
                                        <IconScreenshot className='mr-3 ' />
                                        <span className=' md:pb-0 text-sm whitespace-nowrap'>Laporan</span>
                                    </div>
                                </NavLink>
                            </div>
                        </div> */}
            {/* <div className={`mr-6 my-2 md:my-0 ${check === 'admin'  ? 'hidden' : check === 'operator' ? 'hidden':''}`}>
                            <div className='mr-6 my-2 md:my-0'>
                                <NavLink to='/history-saldo-kasir' className={({ isActive }) => (isActive ? activeNav : normalLink)}>
                                    <div className='flex items-center'>
                                        <IconCurrencyDollar className='mr-3 ' />
                                        <span className=' md:pb-0 text-sm whitespace-nowrap'>History Saldo</span>
                                    </div>
                                </NavLink>
                            </div>
                        </div> */}
            <div
              className={`mr-6 mt-5 my-2 md:my-0 ${
                check === 'kasir' ? 'hidden' : check === 'operator' ? 'hidden' : ''
              }`}
            >
              <div className="dropdown dropdown-bottom w-full">
                <div
                  tabIndex={0}
                  className=" block py-1 md:py-3 pl-1 align-middle cursor-pointer lg:flex items-center  no-underline hover:text-green-500 hover:border-green-600 hover:border-b-2"
                >
                  <div className="flex item-center justify-between">
                    <div className="flex items-center">
                      <IconDeviceDesktopAnalytics className=" mr-3" />
                      <span className="pb-1 md:pb-0 text-sm whitespace-nowrap">Barang</span>
                    </div>
                    <IconChevronDown className="stroke-1" />
                  </div>
                </div>
                <div tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded md:w-52 w-full">
                  <li>
                    <span onClick={openModal}>Jenis Barang</span>
                  </li>
                  <li>
                    <Link to="/list-items-admin">Barang</Link>
                  </li>
                  <li>
                    <Link to="/barang-opname">Stok Opname</Link>
                  </li>
                </div>
              </div>
            </div>
            <div className={`mr-6 my-2 md:my-0 ${check === 'kasir' ? 'hidden' : check === 'operator' ? 'hidden' : ''}`}>
              <div className="dropdown dropdown-bottom w-full">
                <label
                  tabIndex={0}
                  className=" block py-1 md:py-3 pl-1 align-middle cursor-pointer lg:flex items-center  no-underline hover:text-green-500 hover:border-green-600 hover:border-b-2"
                >
                  <div className="flex item-center justify-between">
                    <div className="flex items-center">
                      <IconApps className=" mr-3" />
                      <span className="md:pb-0 text-sm whitespace-nowrap">Transaksi</span>
                    </div>
                    <IconChevronDown className="stroke-1" />
                  </div>
                </label>
                <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded w-52">
                  <li>
                    <Link to="/transaksi-penjualan">Penjualan</Link>
                  </li>
                  <li>
                    <Link to="/transaksi-pembelian">Pembelian</Link>
                  </li>
                  <li>
                    <Link to="/summary">Laporan</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={`mr-6 my-2 md:my-0 mt-2 ${
                check === 'kasir' ? 'hidden' : check === 'operator' ? 'hidden' : ''
              }`}
            >
              <div className="mr-6 my-2 md:my-0">
                <NavLink to="/list-users" className={({ isActive }) => (isActive ? activeNav : normalLink)}>
                  <div className="flex items-center">
                    <IconUser className="mr-3 " />
                    <span className=" md:pb-0 text-sm whitespace-nowrap">Users</span>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className={`mr-6 my-2 md:my-0 ${check === 'kasir' ? 'hidden' : check === 'operator' ? 'hidden' : ''}`}>
              <div className="mr-6 my-2 md:my-0">
                <NavLink to="/saldo-kas" className={({ isActive }) => (isActive ? activeNav : normalLink)}>
                  <div className="flex items-center">
                    <IconBrandCashapp className="mr-3 " />
                    <span className=" md:pb-0 text-sm whitespace-nowrap">Saldo KAS</span>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className={`mr-6 my-2 md:my-0 ${check === 'kasir' ? 'hidden' : ''}`}>
              <div className="mr-6 my-2 md:my-0">
                <NavLink to="/tambah-barang" className={({ isActive }) => (isActive ? activeNav : normalLink)}>
                  <div className="flex items-center">
                    <IconBrandCashapp className="mr-3 " />
                    <span className=" md:pb-0 text-sm whitespace-nowrap">Tambah Barang</span>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
