import React, { useEffect, useState } from 'react';
import Layouts from '../layouts/Layouts';
import { getAuthorizationHeader, http } from '../config/http';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { IconGraph } from '@tabler/icons-react';
import { IconCalendarTime } from '@tabler/icons-react';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';

const LabaPenjualan = () => {
    const [labaPenjualan, setLabaPenjualan] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState(null);
    const awal = moment(startDate).format('DD-MM-YYYY');
    const akhir = moment(endDate).format('DD-MM-YYYY');
    const navigate = useNavigate();

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    function handleSelect(date) {
        setStartDate(date.selection.startDate);
        setEndDate(date.selection.endDate);
    }

    useEffect(() => {
        async function getLabaPenjualan() {
            setLoading(true)
            await http
                .get(`summary/card${startDate === null && endDate === null ? `` : `?tgl_awal=${awal}&tgl_akhir=${akhir}`
                    }`, {
                    headers: { Authorization: getAuthorizationHeader() },
                })
                .then((res) => {
                    setLabaPenjualan(res.data);
                    setLoading(false)
                })
                .catch((err) => {
                    if (err.code === 'ERR_NETWORK') {
                        toast.warning('Periksa jaringan internet', { autoClose: 600, });
                    } else if (err.response.status === 401) {
                        navigate('/login');
                    }
                });
            setLoading(false)

        }
        getLabaPenjualan();
    }, [navigate, awal, akhir,]);
    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
    };

    let total_laba = 0;
    let total_item_penjualan = 0;
    let total_berat_penjualan = 0;
    let total_penjualan = 0

    let total_item_pembelian = 0;
    let total_berat_pembelian = 0;
    let total_pembelian = 0;


    return (
        <Layouts title={'Laba Penjualan'} compFilter={
            <div className="dropdown dropdown-bottom ">
                <div tabIndex={0} className="bg-orange-500 text-white p-2 cursor-pointer">
                    <IconCalendarTime />
                </div>
                <div tabIndex={0} className="dropdown-content menu -right-52 p-2">
                    <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
                </div>
            </div>
        }>
            {loading ? (
                <div>
                    Loading....
                </div>
            ) : (
                <div className="container mx-auto px-2 py-6">
                    <h2 className="text-xl font-semibold text-gray-700 mb-6">Data Laba Penjualan</h2>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white border border-gray-200">
                            <thead>
                                <tr>
                                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-md font-bold text-gray-600 uppercase tracking-wider">
                                        Toko
                                    </th>
                                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-md font-bold text-gray-600 uppercase tracking-wider">
                                        Laba
                                    </th>
                                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-md font-bold text-gray-600 uppercase tracking-wider">
                                        Penjualan
                                    </th>
                                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-md font-bold text-gray-600 uppercase tracking-wider">
                                        Pembelian
                                    </th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                            {labaPenjualan.map((laba, index) => {
                                total_laba += parseFloat(laba?.total_laba);

                                total_item_penjualan += parseInt(laba.total_item_penjualan);
                                total_berat_penjualan += parseFloat(laba.total_berat_penjualan);
                                total_penjualan += parseFloat(laba.total_penjualan);

                                total_item_pembelian += parseInt(laba.total_item_pembelian);
                                total_berat_pembelian += parseFloat(laba.total_berat_pembelian);
                                total_pembelian += parseFloat(laba.total_pembelian);
                                
                                return (
                                <tr className="hover:bg-gray-50" key={index}>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm font-bold text-gray-700">{laba.nama_cabang}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm font-bold text-green-600">
                                        Rp.{''}
                                        {numberWithCommas(
                                            laba?.total_laba == undefined ? '0' : laba?.total_laba,
                                        )}
                                    </td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm font-bold text-gray-700">
                                        {laba.total_item_penjualan} item | {laba.total_berat_penjualan} gr |
                                        Rp.{''}
                                        {numberWithCommas(
                                            laba?.total_penjualan == undefined ? '0' : laba?.total_penjualan,
                                        )}
                                    </td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm font-bold text-gray-700">
                                        {laba.total_item_pembelian} item | {laba.total_berat_pembelian} gr |
                                        Rp.{''}
                                        {numberWithCommas(
                                            laba?.total_pembelian == undefined ? '0' : laba?.total_pembelian,
                                        )}
                                    </td>
                                    
                                </tr>)
                            })}
                            <tr className="hover:bg-gray-50">
                                <td className="px-6 py-4 border-b border-gray-200 text-sm font-bold text-green-600">Total</td>
                                <td className="px-6 py-4 border-b border-gray-200 text-sm font-bold text-green-600">
                                    Rp.{''}
                                    {numberWithCommas(
                                        total_laba == undefined ? '0' : total_laba,
                                    )}
                                </td>
                                <td className="px-6 py-4 border-b border-gray-200 text-sm font-bold text-green-600">
                                    {total_item_penjualan} item | {total_berat_penjualan.toFixed(3)} gr |
                                    Rp.{''}
                                    {numberWithCommas(
                                        total_penjualan == undefined ? '0' : total_penjualan,
                                    )}
                                </td>
                                <td className="px-6 py-4 border-b border-gray-200 text-sm font-bold text-green-600">
                                    {total_item_pembelian} item | {total_berat_pembelian.toFixed(3)} gr |
                                    Rp.{''}
                                    {numberWithCommas(
                                        total_pembelian == undefined ? '0' : total_pembelian,
                                    )}
                                </td>
                                
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

        </Layouts>
    );
};

export default LabaPenjualan;