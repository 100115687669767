import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuthorizationHeader, http, imageBaseUrl } from '../../config/http';
import { PhotoView } from 'react-photo-view';
import { IconCalendarTime, IconChevronLeft, IconChevronRight, IconListDetails, IconReload, IconSearch, IconTransferIn } from '@tabler/icons-react';

import Input from '../../components/Input';
import moment from 'moment';
import useCabang from '../features/useCabang';
import { DinamicTitle } from '../../utils/DinamicTitle';
import { DateRangePicker } from 'react-date-range';
import Layouts from '../../layouts/Layouts';
import useKarat from '../features/useKarat';

const GaleriBarang = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalModal, setTotalModal] = useState(0);
    const [totalModalFilter, setTotalModalFilter] = useState(0);
    const [jmlBarang, setJmlBarang] = useState(0);
    const [jmlBerat, setJmlBerat] = useState(0);
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const navigate = useNavigate();
    const [selectedKategori, setSelectedKategori] = useState('');
    const [selectJenisKategori, setSelectJenisKategori] = useState('');
    const [kategori, setKategori] = useState([]);
    const [selectStatus, setSelectStatus] = useState('');
    const [bakiId, setBakiId] = useState('');
    const [filterBaki, setFilterBaki] = useState([]);
    const [selectCabang, setSelectCabang] = useState('');
    const { karatOption, karatOptionId, setKaratOptionId } = useKarat();
    const [berat, setBerat] = useState('');
    const { cabang } = useCabang();


    const [filterBerat, setFilterBerat] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    const awal = moment(startDate).format('DD-MM-YYYY');
    const akhir = moment(endDate).format('DD-MM-YYYY');
    const filterBeratBarang = () => {
        setBerat(filterBerat);
    };

    useEffect(() => {
        const numbersArray = [];
        for (let i = 1; i <= 20; i++) {
            numbersArray.push(i);
        }
        setFilterBaki(numbersArray);
    }, []);




    const [selectedItems, setSelectedItems] = useState([]);
    const [isCheckedAll, setIsCheckedAll] = useState(false);

    function reloadDate() {
        getItems();
    }
    async function getKategori() {
        await http
            .get('/kategori', {
                headers: { Authorization: getAuthorizationHeader() },
            })
            .then((res) => {
                setKategori(res.data);
            })
            .catch((err) => {
                if (err.response === 'undefined') {
                    return null;
                } else if (err.response.status === 401) {
                    navigate('/login');
                }
            });
    }
    useEffect(() => {
        getKategori();
    }, [navigate]);

    function prevPage() {
        setCurrentPage(currentPage - 1);
        setSelectedItems([]);
        setIsCheckedAll(false);
    }


    const [search, setSearch] = useState('');
    DinamicTitle('Galeri Barang')
    async function getItems() {
        setLoading(true);
        try {
            const res = await http.get(
                `/item?per_page=10&${search === '' ? `&page=${currentPage}` : `&kode=${search}`}${selectedKategori === '' ? `` : `&kategori=${selectedKategori}`}${selectJenisKategori === '' ? `` : `&jenis_kategori=${selectJenisKategori}`}${selectStatus === '' ? `` : `&status=${selectStatus}`}${selectCabang === '' ? `` : `&cabang=${selectCabang}`}${startDate === null && endDate === null ? `` : `&tgl_awal=${awal}&tgl_akhir=${akhir}`}${bakiId === '' ? '' : `&no_baki=${bakiId}`}${karatOptionId === '' ? '' : `&karat=${karatOptionId}`}${berat === '' ? '' : `&berat=${berat}`}`,
                {
                    headers: { Authorization: getAuthorizationHeader() },
                }
            );

            setItems(res.data.data.data);
            setTotalModal(res.data.total_modal);
            setTotalModalFilter(res.data.total_modal_filter);
            setJmlBarang(res.data.data.total);
            setPage(res.data.data.last_page);
            setJmlBerat(res.data.total_berat_page);
            setItemsPerPage(res.data.data.per_page);
            setCurrentPage(res.data.data.current_page);
        } catch (err) {
            if (err.response === undefined) {
                console.error('Network error:', err);
            } else if (err.response.status === 401) {
                navigate('/login');
            }
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        getItems();
    }, [
        selectedKategori,
        bakiId,
        // isDelete,
        search.length >= 10 ? search : '',
        selectJenisKategori,
        // isEdit,
        currentPage,
        selectStatus,
        selectCabang,
        awal,
        akhir,
        // isSubmitItem,
        karatOptionId,
        berat,
    ]);
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const handleKategoriChange = (event) => {
        setSelectedKategori(event.target.value);
        setSelectJenisKategori('');
    };
    function nextPage() {
        setCurrentPage(currentPage + 1);
        setSelectedItems([]);
        setIsCheckedAll(false);
    }
    const handleJenisKategoriChange = (event) => {
        setSelectJenisKategori(event.target.value);
    };
    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
      };
      function handleSelect(date) {
        setStartDate(date.selection.startDate);
        setEndDate(date.selection.endDate);
      }

    return (
        <Layouts title={'Galeri Barang'}
            compFilter={
                <div className="flex items-center gap-0.5">
                    <div className="dropdown dropdown-bottom ">
                        <div tabIndex={0} className="bg-orange-500 text-white p-2 cursor-pointer">
                            <IconCalendarTime />
                        </div>
                        <div tabIndex={0} className="dropdown-content menu -right-52 p-2">
                            <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
                        </div>
                    </div>
                    <button onClick={() => window.location.reload()} className="bg-slate-800 text-white p-2 cursor-pointer">
                        <IconReload className="text-white" />
                    </button>
                    <Input
                        onChange={(e) => setSearch(e.target.value)}
                        className="input input-bordered rounded-none p-1 bg-white"
                        autoComplete="off"
                        type="search"
                        name="search"
                        placeholder="Cari Kode Barang"
                    />
                </div>
            }
        >
            <div className="flex bg-white items-center gap-3 mt-0.5">
                {/* filter */}
                <div className="flex items-center gap-3 w-full scrollbar overflow-x-auto  ">
                    <div className="flex items-center gap-0.5 justify-between">
                        <div className="">
                            <div className="relative group ">
                                <select
                                    onChange={(e) => setSelectCabang(e.target.value)}
                                    className="p-2 border cursor-pointer text-black bg-white"
                                >
                                    <option className="pt-6" value="">
                                        ETALASE
                                    </option>
                                    {cabang.map((cb) => (
                                        <option key={cb.id} value={cb.id}>
                                            {cb.nama_cabang}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div>
                            <div className="relative group">
                                <select
                                    value={selectedKategori}
                                    onChange={handleKategoriChange}
                                    className="border p-2 cursor-pointer text-black bg-white"
                                >
                                    <option className="pt-6" value="">
                                        KATEGORI
                                    </option>
                                    {kategori.map((kt) => (
                                        <option key={kt.id} value={kt.id}>
                                            {kt.nama_kategori}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="">
                            <div className="relative group">
                                {selectedKategori && (
                                    <select
                                        onChange={handleJenisKategoriChange}
                                        value={selectJenisKategori}
                                        className="p-2 border cursor-pointer text-black bg-white"
                                    >
                                        <option value="">JENIS KATEGORI</option>
                                        {kategori
                                            .find((kt) => kt.id == selectedKategori)
                                            ?.jenis_kategori.map((jk) => (
                                                <option key={jk.id} value={jk.id}>
                                                    {jk.nama_jenis}
                                                </option>
                                            ))}
                                    </select>
                                )}
                            </div>
                        </div>
                        <div className="">
                            <div className="relative group ">
                                <select
                                    onChange={(e) => setSelectStatus(e.target.value)}
                                    className="p-2 border cursor-pointer text-black bg-white"
                                >
                                    <option className="pt-6" value="">STATUS</option>
                                    <option className="pt-6" value={1}>
                                        Tersedia
                                    </option>
                                    <option className="pt-6" value={0}>
                                        Tidak Tersedia
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div className="">
                            <div className="relative group ">
                                <select
                                    onChange={(e) => setBakiId(e.target.value)}
                                    className="p-2 border cursor-pointer text-black bg-white"
                                >
                                    <option className="pt-6" value="">BAKI</option>
                                    {filterBaki.map((ftb) => {
                                        return (
                                            <option value={ftb} key={ftb}>
                                                {ftb}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="">
                            <div className="relative group ">
                                <select
                                    onChange={(e) => setKaratOptionId(e.target.value)}
                                    className="p-2 border cursor-pointer text-black bg-white"
                                >
                                    <option className="pt-6" value="">
                                        KARAT
                                    </option>
                                    {karatOption.map((kto) => {
                                        return (
                                            <option value={kto} key={kto}>
                                                {kto}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>

                        </div>
                        {/* <div className="max-w-lg flex w-52 gap-0.5 items-center">
                            <Input onChange={(e) => setFilterBerat(e.target.value)} placeholder="Cari Berat" />
                            <button onClick={filterBeratBarang} className="bg-teal-500 px-1.5 h-[2.30rem] text-white">
                                <IconSearch />
                            </button>
                        </div> */}
                    </div>
                    {/* <div className="flex w-full justify-end md:mr-1">
                        <div className="flex gap-0.5 items-center justify-end">
                            <button onClick={reloadDate} className="bg-slate-800 text-white p-1.5 cursor-pointer">
                                <IconReload className="text-white" />
                            </button>

                        </div>
                    </div> */}
                </div>
            </div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4 mb-20">
                    {items.map((item, index) => {
                        // console.log(item);
                        return (
                            <div key={index} className="relative group">
                                <PhotoView

                                    src={item?.gambar1 === null ? '' : `${imageBaseUrl}${item?.gambar1[0]?.asli}`}
                                >
                                    <img
                                        src={item?.gambar1 === null ? '' : `${imageBaseUrl}${item?.gambar1[0]?.asli}`}
                                        alt=""
                                        className="w-full h-auto rounded cursor-pointer"
                                    />
                                </PhotoView>

                                <p className="absolute top-0 left-0 m-2 rounded text-gray bg-yellow-500 p-1">
                                    {item?.cabang?.nama_cabang}
                                </p>
                                <p className="absolute top-0 right-0 m-2 rounded text-gray bg-yellow-500 p-1">
                                    {item?.karat} krt
                                </p>
                               
                                <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 pt-2 px-2 flex flex-col justify-center gap-0">
                                    {/* <p className="text-white text-base font-bold -mb-2">{item.kode_item} | {item.jenis_kategori?.nama_jenis}</p> */}
                                    <p className="text-white text-base -mb-1">{item?.kode_item} | {item?.jenis_kategori?.nama_jenis}</p>
                                    {/* <p className="text-white text-base -mb-2"></p> */}
                                    {/* <p className="text-white text-base mb-1">{item?.berat} gr | {item?.karat} krt |  */}
                                    <p className="text-white text-base mb-1">{item?.berat} gr | 
                                    Modal: Rp {numberWithCommas(item?.harga_modal === undefined ? '0' : item?.harga_modal)}
                                    {/* Rp{' '}
                                    {numberWithCommas(
                                        trx.total_harga == undefined ? '0' : Math.round(trx.total_harga / trx.item?.berat),
                                    )}{' '}
                                    / gr */}
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}

            <div className="bg-white bottom-0 left-0 py-2 w-full absolute">
                <div className="flex justify-between">
                    <div className="lg:flex hidden items-center  gap-4 mx-3 lg:mx-10">
                        <div className="md:flex items-center">
                            <div className="text-base underline uppercase ">MODAL : &nbsp; </div>
                            <div className="text-base whitespace-nowrap font-semibold text-slate-800">
                                Rp {numberWithCommas(totalModal == totalModalFilter ? totalModal : totalModalFilter)}
                            </div>
                        </div>
                        <div className="md:flex items-center">
                            <div className="text-sm md:text-base whitespace-nowrap underline  uppercase ">Total Barang : </div>
                            <div className="text-base font-semibold text-slate-800">&nbsp; {jmlBarang} barang</div>
                        </div>
                        <div className="md:flex items-center">
                            <div className="text-sm md:text-base whitespace-nowrap underline  uppercase ">Total Berat : </div>
                            <div className="text-base font-semibold text-slate-800">&nbsp; {jmlBerat} gram</div>
                        </div>
                    </div>
                    {/* mobile */}
                    <div className="dropdown dropdown-top lg:hidden mx-3 lg:mx-10">
                        <div
                            tabIndex={0}
                            className="bg-green-600 cursor-pointer hover:bg-green-700 hover:shadow-md hover:scale-110 p-2 text-white rounded-md"
                        >
                            <IconListDetails />
                        </div>
                        <div tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-[350px]">
                            <div className="  gap-4 mx-10">
                                <div className="flex items-center justify-between mb-4 border-b">
                                    <div className="text-base font-semibold uppercase ">MODAL : </div>
                                    <div className="text-base whitespace-nowrap font-semibold text-slate-800">
                                        Rp {numberWithCommas(totalModal == totalModalFilter ? totalModal : totalModalFilter)}
                                    </div>
                                </div>
                                <div className="flex items-center justify-between mb-4 border-b">
                                    <div className="text-base font-semibold uppercase ">Total Barang : </div>
                                    <div className="text-base font-semibold text-slate-800">{jmlBarang} barang</div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="text-base font-semibold uppercase">Total Berat : </div>
                                    <div className="text-base font-semibold text-slate-800"> {jmlBerat} gram</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="items-center flex justify-center space-y-2 text-xs sm:space-y-0 sm:space-x-3 mr-4 sm:flex">
                        <span className="block">
                            Page {currentPage} of {page}
                        </span>
                        <div className="space-x-1">
                            <button
                                onClick={prevPage}
                                disabled={currentPage < 1}
                                title="previous"
                                type="button"
                                className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow"
                            >
                                <IconChevronLeft />
                            </button>
                            <button
                                onClick={nextPage}
                                title="next"
                                type="button"
                                className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow"
                            >
                                <IconChevronRight />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Layouts>
    );
};

export default GaleriBarang;
