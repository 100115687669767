import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { getAuthorizationHeader, http } from '../config/http';
import { CurrencyInput } from '../utils/CurrencyInput';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function TambahSaldoKAS({ closeModalTambahSaldo, isOpenTambahSaldo, submitTambahSaldoKas }) {
  const [cabang_id, setCabangId] = useState('');
  const [nominal, setNominal] = useState(0);
  const [status, setStatus] = useState('');
  const [keterangan, setKeterangan] = useState('');
  const [loadingTambahSaldo, setLoadingTambahSaldo] = useState(false);
  const [cabang, setCabang] = useState([]);
  const navigate = useNavigate();

  function resetState() {
    setCabangId('');
    setNominal(0);
    setStatus('');
  }
  // console.log('hallo')
  useEffect(() => {
    async function getSaldoCabang() {
      await http
        .get(`/cabang?is_toko=1`, {
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then((res) => {
          // console.log('res::: ', res);
          setCabang(res.data.data);
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            return null;
          } else if (err.response.status === 401) {
            navigate('/login');
          }
        });
    }
    getSaldoCabang();
  }, [navigate]);

  async function handleSubmitTambahSaldo() {
    setLoadingTambahSaldo(true);
    const data = {
      cabang_id,
      nominal,
      status,
      keterangan,
    };
    try {
      const response = await http.post('saldo', data, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      toast.success(response.data.message, { autoClose: 600, });
      setLoadingTambahSaldo(false);
      closeModalTambahSaldo();
      submitTambahSaldoKas();
      resetState();
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
    setLoadingTambahSaldo(true);
  }

  return (
    <>
      <Transition appear show={isOpenTambahSaldo} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModalTambahSaldo}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center p-4 min-h-[440px]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Setor / Tarik Saldo
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="mb-6">
                      <label
                        htmlFor="cabang_id"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Pilih Toko
                      </label>
                      <select
                        id="cabang_id"
                        name="cabang_id"
                        onChange={(e) => setCabangId(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="">Pilih Toko</option>
                        {/* <option value={cabang.id}>{cabang.nama_cabang}</option> */}

                        {cabang.map((cb) => (
                          <option value={cb.id} key={cb.id}>
                            {cb.nama_cabang}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-6">
                      <label htmlFor="nominal" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Masukkan Nominal
                      </label>
                      <div className="mb-6">
                        <CurrencyInput
                          name="nominal"
                          value={nominal}
                          onChange={(value) => setNominal(() => value)}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                    </div>
                    <div className="mb-6">
                      <label htmlFor="status" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Pilih Status
                      </label>
                      <select
                        id="status"
                        name="status"
                        onChange={(e) => setStatus(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="">Pilih Status</option>
                        <option value="setor">Setor</option>
                        <option value="tarik">Tarik</option>
                      </select>
                    </div>
                    <div className="mb-6">
                      <div className="mb-2">Keterangan</div>
                      <textarea
                        id="message"
                        onChange={(e) => setKeterangan(e.target.value)}
                        rows={4}
                        name="keterangan"
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50  border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Keterangan dari transaksi setor/tarik saldo"
                      // defaultValue={formData.keterangan}
                      />
                    </div>
                  </div>

                  <div className="flex justify-end gap-1">
                    <button
                      type="reset"
                      onClick={closeModalTambahSaldo}
                      className="text-white  bg-slate-600 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 font-medium text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800"
                    >
                      Tutup
                    </button>
                    <button
                      type="submit"
                      onClick={handleSubmitTambahSaldo}
                      className="text-white  bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                    >
                      {loadingTambahSaldo ? 'Proses ...' : 'Simpan'}
                    </button>


                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
