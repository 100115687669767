import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getAuthorizationHeader, http } from '../../config/http';
import useKarat from './useKarat';
import moment from 'moment';

const useBarangTerhapus = () => {
    const [barangTerhapus, setBarangTerhapus] = useState([]);
    const [loadingBarangTerhapus, setLoadingBarangTerhapus] = useState(false);
    const { karatOptionId, karatOption, setKaratOptionId } = useKarat();
    const [berat, setBerat] = useState('');
    const [filterBerat, setFilterBerat] = useState('');

    const filterBeratBarang = () => {
        setBerat(filterBerat);
      };

    const [selectedKategori, setSelectedKategori] = useState('');
    const [selectJenisKategori, setSelectJenisKategori] = useState('');
    const [selectCabang, setSelectCabang] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(0);
    function prevPage() {
        setCurrentPage(currentPage - 1);
        // setSelectedItems([]);
        // setIsCheckedAll(false);
    }

    function nextPage() {
        setCurrentPage(currentPage + 1);
        // setSelectedItems([]);
        // setIsCheckedAll(false);
    }

    const [search, setSearch] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const awal = moment(startDate).format('DD-MM-YYYY');
    const akhir = moment(endDate).format('DD-MM-YYYY');
    const navigate = useNavigate();
    async function getBarangTerhapus() {
        setLoadingBarangTerhapus(true);
        await http
            .get(`/trash${search === '' ? `?page=${currentPage}` : `?kode=${search}`}${selectedKategori === '' ? `` : `&kategori=${selectedKategori}`}${selectJenisKategori === '' ? `` : `&jenis_kategori=${selectJenisKategori}`
                }${selectCabang === '' ? `` : `&cabang=${selectCabang}`}${karatOptionId === '' ? '' : `&karat=${karatOptionId}`}${startDate === null && endDate === null ? `` : `&tgl_awal=${awal}&tgl_akhir=${akhir}`
                }${berat === '' ? '' : `&berat=${berat}`}`, {
                headers: { Authorization: getAuthorizationHeader() },
            })
            .then((res) => {
                setBarangTerhapus(res.data.data.data);
                setCurrentPage(res.data.data.current_page);
                setPage(res.data.data.last_page);
            })
            .catch((err) => {
                if (err.response === 'undefined') {
                    return null;
                } else if (err.response.status === 401) {
                    navigate('/login');
                }
            });
        setLoadingBarangTerhapus(false);
    }
    useEffect(() => {
        getBarangTerhapus();
    }, [selectedKategori, berat, selectJenisKategori, karatOptionId, selectCabang, awal, currentPage,
        akhir,]);
    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
    };
    function handleSelect(date) {
        setStartDate(date.selection.startDate);
        setEndDate(date.selection.endDate);
    }
    return {
        barangTerhapus,
        selectionRange,
        loadingBarangTerhapus,
        selectedKategori,
        handleSelect,
        prevPage,
        nextPage,
        currentPage,
        page,
        selectCabang,
        setSelectCabang,
        setSelectedKategori,
        selectJenisKategori,
        setSelectJenisKategori,
        setCurrentPage,
        setSearch,
        karatOption,
        setFilterBerat,
        filterBeratBarang,
        getBarangTerhapus,
        setKaratOptionId
    }
}

export default useBarangTerhapus