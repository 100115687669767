import React, { useEffect, useState } from 'react'
import Layouts from '../layouts/Layouts'
import { getAuthorizationHeader, http } from '../config/http';
import { DinamicTitle } from '../utils/DinamicTitle';
import { useNavigate } from 'react-router-dom';
import useKarat from './features/useKarat';
import { IconCalendarTime } from '@tabler/icons-react';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';

const Riwayat = () => {
    DinamicTitle('Riwayat Transfer');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [cabang, setCabang] = useState([]);
    const [kategori, setKategori] = useState([]);
    const [selectedKategori, setSelectedKategori] = useState('');
    const [selectJenisKategori, setSelectJenisKategori] = useState('');
    const { karatOption, karatOptionId, setKaratOptionId } = useKarat();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const awal = moment(startDate).format('DD-MM-YYYY');
    const akhir = moment(endDate).format('DD-MM-YYYY');
    const [riwayatTransfer, setRiwayatTransfer] = useState([]);
    const [formData, setFormData] = useState({
        cabang_asal: '',
        cabang_tujuan: '',
    });
    async function getKategori() {
        await http
            .get('/kategori', {
                headers: { Authorization: getAuthorizationHeader() },
            })
            .then((res) => {
                setKategori(res.data);
            })
            .catch((err) => {
                if (err.response === 'undefined') {
                    return null;
                } else if (err.response.status === 401) {
                    navigate('/login');
                }
            });
    }
    useEffect(() => {
        getKategori();
    }, [])

    useEffect(() => {
        async function getCabang() {
            await http
                .get('/cabang', {
                    headers: { Authorization: getAuthorizationHeader() },
                })
                .then((res) => {
                    setCabang(res.data.data);
                })
                .catch((err) => {
                    if (err.response === 'undefined') {
                        return null;
                    }
                });
        }
        getCabang();
    }, []);
    function handleOnChange(e) {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    async function getHistoriTransfer() {
        setLoading(true);
        await http.get(`/history_transfer?cabang_asal=${formData.cabang_asal}&cabang_tujuan=${formData.cabang_tujuan}${selectedKategori === '' ? `` : `&kategori=${selectedKategori}`}${selectJenisKategori === '' ? `` : `&jenis_kategori=${selectJenisKategori}`
            }${karatOptionId === '' ? '' : `&karat=${karatOptionId}`}${startDate === null && endDate === null ? `` : `&tgl_awal=${awal}&tgl_akhir=${akhir}`
            }`).then((res) => {
                // console.log(res.data)
                setRiwayatTransfer(res.data.data)
            }).catch((error) => {
                console.log(error)
            })
        setLoading(false);
    }

    useEffect(() => {
        if (formData.cabang_asal && formData.cabang_tujuan !== '') {
            getHistoriTransfer();
        }
    }, [formData.cabang_asal, awal,
        akhir, formData.cabang_tujuan, karatOptionId, selectedKategori, selectJenisKategori])

    const handleKategoriChange = (event) => {
        setSelectedKategori(event.target.value);
        setSelectJenisKategori('');
    };
    const handleJenisKategoriChange = (event) => {
        setSelectJenisKategori(event.target.value);
    };

    function handleSelect(date) {
        setStartDate(date.selection.startDate);
        setEndDate(date.selection.endDate);
    }
    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
    };
    return (
        <Layouts title={'Riwayat Transfer'}
            compFilter={
                <div className="dropdown dropdown-bottom ">
                    <div tabIndex={0} className="bg-orange-500 text-white p-1.5 rounded cursor-pointer">
                        <IconCalendarTime />
                    </div>
                    <div tabIndex={0} className="dropdown-content menu -right-52 ">
                        <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
                    </div>
                </div>
            }
        >
            <div className='mx-6 my-4'>
                <div className="bg-orange-500/20 rounded p-2">
                    Note:
                    <div className='ml-6'>
                        Pastikan anda memilih <span className='font-bold'>cabang asal</span>  dan <span className='font-bold'>cabang tujuan</span>  terlebih dahulu!!
                    </div>
                </div>
                <div>
                    <div className="mt-4 flex gap-1">
                        <select
                            id="cabang_asal"
                            name="cabang_asal"
                            value={formData.cabang_asal}
                            onChange={handleOnChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                            <option value="">Pilih Cabang Asal</option>
                            {cabang.map((cb) => (
                                <option value={cb.id} key={cb.id}>
                                    Asal {cb.nama_cabang}
                                </option>
                            ))}
                        </select>
                        <select
                            id="cabang_tujuan"
                            name="cabang_tujuan"
                            value={formData.cabang_tujuan}
                            onChange={handleOnChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                            <option value="">Pilih Cabang Tujuan</option>
                            {cabang.map((cb) => (
                                <option value={cb.id} key={cb.id}>
                                    Tujuan {cb.nama_cabang}
                                </option>
                            ))}
                        </select>
                        <select
                            value={selectedKategori}
                            onChange={handleKategoriChange}
                            className="border p-2 rounded cursor-pointer"
                        >
                            <option className="pt-6" value="">
                                Pilih Kategori
                            </option>
                            {kategori.map((kt) => (
                                <option key={kt.id} value={kt.id}>
                                    {kt.nama_kategori}
                                </option>
                            ))}
                        </select>
                        {selectedKategori && (
                            <select
                                onChange={handleJenisKategoriChange}
                                value={selectJenisKategori}
                                className="border p-2 rounded cursor-pointer shadow"
                            >
                                <option value="">Pilih Jenis</option>
                                {kategori
                                    .find((kt) => kt.id == selectedKategori)
                                    ?.jenis_kategori.map((jk) => (
                                        <option key={jk.id} value={jk.id}>
                                            {jk.nama_jenis}
                                        </option>
                                    ))}
                            </select>
                        )}
                        <select
                            onChange={(e) => setKaratOptionId(e.target.value)}
                            className="border p-2 rounded-md cursor-pointer shadow-md"
                        >
                            <option className="pt-6" value="">
                                Filter Karat
                            </option>
                            {karatOption.map((kto) => {
                                return (
                                    <option value={kto} key={kto}>
                                        {kto}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className='mt-2'>
                        {loading ? (
                            <div className='ml-3'>
                                Loading....
                            </div>
                        ) : (
                            <>
                                <div className="shadow-lg rounded overflow-hidden">
                                    <table className="w-full table-fixed">
                                        <thead>
                                            <tr className="bg-gray-100">
                                                <th className="w-[5%] py-2 px-3 text-left border-r text-gray-600 font-smibold">No</th>
                                                <th className="w-1/4 py-2 px-3 text-left border-r text-gray-600 font-smibold">Kode Barang</th>
                                                <th className="w-1/4 py-2 px-3 text-left border-r text-gray-600 font-smibold">Nama Barang</th>
                                                <th className="w-1/4 py-2 px-3 text-left border-r text-gray-600 font-smibold">Berat | Karat</th>
                                                <th className="w-1/4 py-2 px-3 text-left border-r text-gray-600 font-smibold">Tanggal Transfer</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white">
                                            {riwayatTransfer.map((rf, i) => (
                                                <tr key={i}>
                                                    <td className="py-2 px-3 border-b border-r border-gray-200">{i + 1}</td>
                                                    <td className="py-2 px-3 border-b border-r border-gray-200 truncate">{rf.kode_item}</td>
                                                    <td className="py-2 px-3 border-b border-r border-gray-200 truncate">{rf.jenis_kategori.nama_jenis}</td>
                                                    <td className="py-2 px-3 border-b border-r border-gray-200">{`${rf.berat} g | ${rf.karat} Krt`}</td>
                                                    <td className="py-2 px-3 border-b border-r border-gray-200">
                                                        {rf.created_at}
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Layouts>
    )
}

export default Riwayat