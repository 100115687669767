import React from 'react';

const Masuk = ({ barangMasuk }) => {
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-md rounded-md overflow-hidden">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs sticky top-0 border-b text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3 whitespace-nowrap">
              No
            </th>
            <th scope="col" className="px-6 py-3 whitespace-nowrap">
              Kode Barang
            </th>
            <th scope="col" className="px-6 py-3 whitespace-nowrap">
              Nama Barang
            </th>
            <th scope="col" className="px-6 py-3 whitespace-nowrap">
              Nomor BAKI
            </th>
            <th scope="col" className="px-6 py-3 whitespace-nowrap">
              Berat
            </th>
            <th scope="col" className="px-6 py-3 whitespace-nowrap">
              Emas
            </th>
            <th scope="col" className="px-6 py-3 whitespace-nowrap">
              Lokasi
            </th>
          </tr>
        </thead>
        <tbody>
          {barangMasuk.map((bm, i) => (
            <tr className="bg-white z-10 hover:bg-slate-100 cursor-pointer">
              <td className="px-5 py-2 border-b border-gray-200  text-sm">
                <div className="ml-3">
                  <span className="text-gray-900  whitespace-nowrap">{i + 1}</span>
                </div>
              </td>
              <td className="px-5 py-2 border-b border-gray-200 text-gray-900  whitespace-nowrap text-sm">
                {bm.kode_item}
              </td>
              <td className="px-5 py-2 border-b border-gray-200  text-sm">
                <span className="text-gray-900  whitespace-nowrap"></span>
              </td>
              <td className="px-5 py-2 border-b border-gray-200  text-sm">
                <span className="text-gray-900  whitespace-nowrap"> krt</span>
              </td>
              <td className="px-5 py-2 border-b whitespace-nowrap border-gray-200  text-sm"></td>
              <td className="px-5 py-2 border-b border-gray-200  text-sm">
                <span className="text-gray-900  whitespace-nowrap"></span>
              </td>
              <td className="px-5 py-2 border-b border-gray-200  text-sm">
                <span className="text-gray-900  whitespace-nowrap"></span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Masuk;
